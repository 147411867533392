import React, { useState, useEffect } from 'react';
import axios from 'axios';
import forms from '../assets/forms.json'; // Import the JSON file (make sure it includes nested permissions if needed)
import '../styles/RolePermissions.css'; // Add modern styles here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus } from '@fortawesome/free-solid-svg-icons';

const RolePermissions = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [newRoleName, setNewRoleName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const rolesAPI = `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_ROLES}`;

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(rolesAPI);
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setPermissions(role.permissions);
  };

  const handlePermissionChange = (formKey, permissionType, value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [formKey]: {
        ...prevPermissions[formKey],
        [permissionType]: value,
      },
    }));
  };

  const handleSavePermissions = async () => {
    setIsSaving(true);
    try {
      await axios.put(`${rolesAPI}/${selectedRole.id}`, {
        ...selectedRole,
        permissions,
      });
      alert('Permissions updated successfully.');
      fetchRoles();
    } catch (error) {
      console.error('Error saving permissions:', error);
      alert('Failed to update permissions.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleAddRole = async () => {
    try {
      const newRole = {
        name: newRoleName,
        permissions: {},
      };
      const response = await axios.post(`${rolesAPI}`, newRole);
      setRoles([...roles, response.data]);
      setNewRoleName('');
      alert('Role added successfully.');
    } catch (error) {
      console.error('Error adding role:', error);
      alert('Failed to add role.');
    }
  };

  return (
    <div className="role-permissions-container">
      <div className="sidebar">
        <h2>Roles</h2>
        {roles.map((role) => (
          <button
            key={role.id}
            onClick={() => handleRoleChange(role)}
            className={`role-button ${selectedRole?.id === role.id ? 'active' : ''}`}
          >
            {role.name}
          </button>
        ))}
        <div className="add-role-container">
          <input
            type="text"
            value={newRoleName}
            onChange={(e) => setNewRoleName(e.target.value)}
            placeholder="New Role Name"
            className="add-role-input"
          />
          <button onClick={handleAddRole} className="add-role-button">
            <FontAwesomeIcon icon={faPlus} /> Add
          </button>
        </div>
      </div>

      {selectedRole && (
        <div className="permissions-section">
          <h2>Permissions for {selectedRole.name}</h2>
          <div className="permissions-accordion">
            {forms.map((form) => (
              <div key={form.key} className="accordion-item">
                <div className="accordion-header">
                  <h3>{form.name}</h3>
                </div>
                <div className="accordion-body">
                  {/* Render top-level permissions for this form */}
                  {form.permissions &&
                    form.permissions.map((permissionType) => (
                      <label key={permissionType} className="permission-label">
                        <input
                          type="checkbox"
                          checked={permissions[form.key]?.[permissionType] === 'true'}
                          onChange={(e) =>
                            handlePermissionChange(form.key, permissionType, e.target.checked.toString())
                          }
                        />
                        {permissionType}
                      </label>
                    ))}
                  {/* If there are nested permissions, render them in a nested container */}
                  {form.children && form.children.length > 0 && (
                    <div className="nested-permissions">
                      {form.children.map((child) => (
                        <div key={child.key} className="nested-permission-item">
                          <h4>{child.name}</h4>
                          {child.permissions &&
                            child.permissions.map((permissionType) => (
                              <label key={permissionType} className="permission-label">
                                <input
                                  type="checkbox"
                                  checked={permissions[child.key]?.[permissionType] === 'true'}
                                  onChange={(e) =>
                                    handlePermissionChange(child.key, permissionType, e.target.checked.toString())
                                  }
                                />
                                {permissionType}
                              </label>
                            ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={handleSavePermissions}
            className={`btn-save ${isSaving ? 'saving' : ''}`}
            disabled={isSaving}
          >
            <FontAwesomeIcon icon={faSave} /> {isSaving ? 'Saving...' : 'Save Permissions'}
          </button>
        </div>
      )}
    </div>
  );
};

export default RolePermissions;
