import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import axios from 'axios';
import { setupAxiosInterceptors, checkPermission } from '../assets/Utils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [rolePermissions, setRolePermissions] = useState({});
  const [loading, setLoading] = useState(true);
  
  // Use useRef to store the idle timer.
  const idleTimerRef = useRef(null);
  const idleTimeout = 30 * 60 * 1000; // 10 minutes

  const resetIdleTimer = () => {
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }
    idleTimerRef.current = setTimeout(() => {
      logout();
      alert('You have been logged out due to inactivity.');
    }, idleTimeout);
  };

  useEffect(() => {
    setupAxiosInterceptors();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const idToken = await firebaseUser.getIdToken(true);
          setToken(idToken);

          const userResponse = await axios.get(
            `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_USERS}/firebase/${firebaseUser.uid}`,
            { headers: { Authorization: `Bearer ${idToken}` } }
          );
          setUser(userResponse.data);

          const roleResponse = await axios.get(
            `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_ROLES}/${userResponse.data.role}`,
            { headers: { Authorization: `Bearer ${idToken}` } }
          );
          setRolePermissions(roleResponse.data.permissions);
        } catch (error) {
          console.error('Error fetching user or permissions:', error);
        }
      } else {
        setUser(null);
        setToken(null);
        setRolePermissions({});
      }
      setLoading(false);
      resetIdleTimer();
    });

    // Add event listeners to reset idle timer on user activity.
    const events = ['mousemove', 'keydown', 'click', 'scroll'];
    events.forEach((event) => {
      window.addEventListener(event, resetIdleTimer);
    });

    return () => {
      unsubscribe();
      events.forEach((event) => {
        window.removeEventListener(event, resetIdleTimer);
      });
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
    };
  }, []);

  const login = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const firebaseUser = userCredential.user;

    const idToken = await firebaseUser.getIdToken(true);
    setToken(idToken);

    const userResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_USERS}/firebase/${firebaseUser.uid}`,
      { headers: { Authorization: `Bearer ${idToken}` } }
    );
    setUser(userResponse.data);

    const roleResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_ROLES}/${userResponse.data.role}`,
      { headers: { Authorization: `Bearer ${idToken}` } }
    );
    setRolePermissions(roleResponse.data.permissions);
    resetIdleTimer();
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
    setToken(null);
    setRolePermissions({});
    if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
  };

  // Expose a helper for permission checking.
  const hasPermission = (key, type) => {
    return checkPermission(rolePermissions, key, type);
  };

  return (
    <AuthContext.Provider value={{ user, token, rolePermissions, login, logout, loading, hasPermission }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
