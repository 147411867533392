import React from 'react';
import DataList from './DataList';

const UserManagement = () => {
  const apiConfig = {
    title: 'User Management',
    path: '/user-management',
    fetchEndpoint: '/hospital-portal/api/users',
    createEndpoint: '/hospital-portal/api/users',
    updateEndpoint: '/hospital-portal/api/users',
    deleteEndpoint: '/hospital-portal/api/users',
    defaultSortFieldId: 'name',
    allowAdd: true,
    bulkActions: [], // Add bulk actions if required
    dropdownFields: [
      {
        name: 'role',
        endpoint: '/hospital-portal/api/roles',
        valueField: 'name',
        labelField: 'name',
      },],
    fields: [
      {
        name: 'id',
        label: 'ID',
        type: 'text',
        disabled: true,
        identifier:true,
        tab: 'Main',
      },
      {
        name: 'firebase_uid',
        label: 'Firebase UID',
        required: true,
        type: 'text',
        tab: 'Main',
      },
      {
        name: 'name',
        label: 'Name',
        type: 'text',
        required: true,
        tab: 'Main',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
        tab: 'Main',
      },
      {
        name: 'role',
        label: 'Role',
        type: 'dropdown',
        required: true,
        tab: 'Main',
      },
      
    ],
  };

  const columns = [
    { name: 'ID', selector: (row) => row.id, sortable: true,identifier:true, width: '50px', },
    { name: 'Name', selector: (row) => row.name, sortable: true,filterable: true,filterSelector: 'name' },
    { name: 'Email', selector: (row) => row.email, sortable: true },
    { name: 'Firebsae ID', selector: (row) => row.firebase_uid,style: {width: 'auto'} },
    { name: 'Role', selector: (row) => row.role, sortable: true,filterable: true,filterSelector: 'role' },
  ];

  return <DataList apiConfig={apiConfig} columns={columns} />;
};

export default UserManagement;
