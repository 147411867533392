import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClipboardList, faCogs, faFileInvoiceDollar,faChartBar,faUserTie, faPerson, faHospital, faBedPulse, faLaptopMedical, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import '../../styles/LandingPage.css'; // Add styles here
import { useAuth } from '../../hooks/useAuth';


const LabTestDashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {rolePermissions } = useAuth();



function MyLink (props) { 
  const { path, iconname, name} = props;
  console.log("Permissions: ", rolePermissions);
  if(rolePermissions[props.path]?.View === 'true') {
    console.log("We have permission!");
   return (
  <Link to= {path}
        className="link-card">
    <FontAwesomeIcon icon={iconname} size="3x" className="icon"/><p>{name}</p>
  </Link>);
  }
};

  return (
        <div className="landing-page">

            <div className="links-container">
              <MyLink path="/labTests" iconname={faLaptopMedical} name="Lab Test"/>
              <MyLink path="/lab-requests" iconname={faFileMedical} name="Lab Requests"/>
            </div>

        </div>
  );
};

export default LabTestDashboard;
