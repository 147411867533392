import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/SalesView.css';

const SalesView = ({ salesData, salesNumber }) => {
  const [formData, setFormData] = useState({
    header: {
      sales_type: '',
      customer_name: '',
      patient_name: '',
      patient_id: '',
      patient_mobile: '',
      sales_date: '',
      sales_number: '',
      sales_total: '',
      sales_adjustments: '',
    },
    items: [],
  });

  useEffect(() => {
    if (salesData && salesData.sales_id) {
      fetchSalesDetails(salesData.sales_id);
    } else if (salesNumber) {
      fetchSalesDetailsByNumber(salesNumber);
    }
  }, [salesData, salesNumber]);

  const fetchSalesDetails = async (salesId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_SALES_TRANSACTIONS}/${salesId}`);
      const headerItems = { ...response.data.header[0] };
      const recordToEdit = { header: headerItems, items: response.data.lineItems };
      setFormData(recordToEdit);
    } catch (error) {
      console.error('Error fetching sale details:', error);
    }
  };

  const fetchSalesDetailsByNumber = async (salesNumber) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_SALES_TRANSACTIONS}?salesNumber=${salesNumber}`);
      const headerItems = { ...response.data.header[0] };
      const recordToEdit = { header: headerItems, items: response.data.lineItems };
      setFormData(recordToEdit);
    } catch (error) {
      console.error('Error fetching sale details by number:', error);
    }
  };

  const getBranch = (salesNumber) => {
    return salesNumber.startsWith('K-') ? 'Kissy Street' : 'Pultney Street';
  };

  return (
    <div className="sales-view-container">
      <div className="invoice-header">
        <div className="invoice-title">
          <h2>{formData.header.sales_type}</h2>
          <h3>{formData.header.sales_number}</h3>
        </div>
        <div className="invoice-details">
          <div><strong>Invoice Date:</strong> {new Date(formData.header.sales_date).toLocaleDateString()}</div>
          <div><strong>Patient Name:</strong> {formData.header.patient_name}</div>
          <div><strong>Patient ID:</strong> {formData.header.patient_id}</div>
          <div><strong>Patient Mobile:</strong> {formData.header.patient_mobile}</div>
          <div><strong>Branch:</strong> {getBranch(formData.header.sales_number)}</div>
          {formData.header.sales_type === 'Invoice' && (
            <div><strong>Customer:</strong> {formData.header.customer_name}</div>
          )}
        </div>
      </div>
      <div className="invoice-items">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {formData.items.map((item, index) => (
              <tr key={index}>
                <td>{item.line_description}</td>
                <td>{parseFloat(item.line_total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="invoice-summary">
        <div><strong>Adjustments:</strong> {parseFloat(formData.header.sales_adjustments).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        <div><strong>Total:</strong> {parseFloat(formData.header.sales_total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
      </div>
    </div>
  );
};

export default SalesView;
