import React from 'react';
import DataList from './DataList';
import { getFormattedDate } from '../assets/Utils';

const MedicalContractList = () => {
  const apiConfig = {
    title: 'Medical Contracts',
    path: '/medical-contract-list',
    fetchEndpoint: '/hospital-portal/api/medicalreports/medical-contract/',
    createEndpoint: '/hospital-portal/api/medicalreports/medical-contract/form',
    updateEndpoint: '/hospital-portal/api/medicalreports/medical-contract/form',
    deleteEndpoint: '/hospital-portal/api/medicalreports/medical-contract/form',
    defaultSortFieldId: 'id',
    defaultSortAsc: false,
    dropdownFields: [
      {
        name: 'customer_name',
        endpoint: '/hospital-portal/api/customers?status=Active',
        valueField: 'customer_name',
        labelField: 'customer_name',
      },],
    bulkActions: [
      { name: 'Generate Report', endpoint: '/hospital-portal/api/medical-contracts/generate-report' },
    ],
    fields: [
      { name: 'id', label: 'ID', type: 'number', identifier: true, disabled: true, tab: 'Main' },
      { name: 'customer_name', label: 'Customer Name', type: 'dropdown', required: true, tab: 'Main' },
      { name: 'batch', label: 'Batch', type: 'text', required: true, tab: 'Main',required:true},
      { name: 'access_accounts', label: 'Access', type: 'textarea', required: true, tab: 'Main',maxRows:5},
      { name: 'total_price', label: 'Total Price', type: 'number', tab: 'Details',required:true },
      { name: 'employee_count', label: 'Employee Count', type: 'number', tab: 'Details',required:true },
      { name: 'columns_config', label: 'Column Configuration', type: 'textarea', maxRows: 10, tab: 'Configuration', allowJson: true },
      { name: 'fields_config', label: 'Field Configuration', type: 'textarea', maxRows: 10, tab: 'Configuration', allowJson: true },
    ],
    tabs: [
      { name: 'Main', roles: 'all', default: true },
      { name: 'Details', roles: 'all' },
      { name: 'Configuration', roles: 'Admin' },
    ],
  };

  const columns = [
    { id: 'id', name: 'ID', selector: (row) => row.id, type: 'integer', sortable: true, identifier: true, width: '50px' },
    { name: 'Customer Name', selector: (row) => row.customer_name, sortable: true, filterable: true, filterSelector: 'customer_name' },
    { name: 'Batch', selector: (row) => row.batch, sortable: true },
    { name: 'Total Price', selector: (row) => row.total_price, sortable: true },
    { name: 'Employee Count', selector: (row) => row.employee_count, sortable: true },
    { name: 'Status', selector: (row) => row.status, sortable: true },
  ];

  return <DataList apiConfig={apiConfig} columns={columns} />;
};

export default MedicalContractList;
