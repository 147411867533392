import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/DoctorSalesDetails.css'; 
import { format } from 'date-fns';
import logo from '../assets/logo192.png';

const DoctorSalesDetails = () => {
  const [salesDetails, setSalesDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const doctorName = queryParams.get('doctor');
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const consultationPer = 0.5;
  const observationPer = 0.1;
  const surgeryPer = 1.0;
  const ultrasoundPer = 0.5;
  const labMedicationPer = 0.1;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDoctorSalesDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${process.env.REACT_APP_API_DOCTOR_SALES_DETAILS}`, {
          params: {
            doctorName: doctorName,
            startDate: startDate,
            endDate: endDate,
          },
        });
        setSalesDetails(response.data);
      } catch (error) {
        console.error('Error fetching doctor sales details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (doctorName) {
      fetchDoctorSalesDetails();
    }
  }, [doctorName, startDate, endDate]);

  const formatDateRange = (start, end) => {
    const formattedStart = format(new Date(start), 'MMM dd yyyy');
    const formattedEnd = format(new Date(end), 'MMM dd yyyy');
    return `${formattedStart} - ${formattedEnd}`;
  };

  const calculateEarnings = (row) => {
    const consultations = parseFloat(row.Consultation || 0) * consultationPer;
    const observations = parseFloat(row.Observation || 0) * observationPer;
    const surgeries = parseFloat(row.Surgery || 0) * surgeryPer;
    const ultrasound = parseFloat(row.Ultrasound || 0) * ultrasoundPer;
    const labMedications = parseFloat(row['Lab and Medication'] || 0) * labMedicationPer;
    return consultations + observations + surgeries + ultrasound + labMedications;
  };

  const calculateGrandTotal = () => {
    return salesDetails.reduce((acc, row) => acc + calculateEarnings(row), 0).toFixed(2);
  };

  const earningsTableData = [
    { label: 'Consultations', percentage: 50, earnings: salesDetails.reduce((acc, row) => acc + (parseFloat(row.Consultation || 0) * consultationPer), 0).toFixed(2) },
    { label: 'Observations', percentage: 10, earnings: salesDetails.reduce((acc, row) => acc + (parseFloat(row.Observation || 0) * observationPer), 0).toFixed(2) },
    { label: 'Surgeon Fees', percentage: 100, earnings: salesDetails.reduce((acc, row) => acc + (parseFloat(row.Surgery || 0) * surgeryPer), 0).toFixed(2) },
    { label: 'Ultrasound', percentage: 50, earnings: salesDetails.reduce((acc, row) => acc + (parseFloat(row.Ultrasound || 0) * ultrasoundPer), 0).toFixed(2) },
    { label: 'Lab & Medication', percentage: 10, earnings: salesDetails.reduce((acc, row) => acc + (parseFloat(row['Lab and Medication'] || 0) * labMedicationPer), 0).toFixed(2) },
  ];
const dataColumnWdith = '90px';
  const columns = [
    { 
      name: 'Sales #', 
      selector: row => row.sales_number, 
      sortable: true,
      minWidth: '50px',
      maxWidth: '120px',
    },
    { 
      name: 'Patient', 
      selector: row => row.patient_name, 
      sortable: true,
      minWidth: '75px',
    },
    { 
      name: 'Date', 
      selector: row => row.sales_date, 
      sortable: true,
      minWidth: '50px',
      maxWidth: '140px',
    },
    { 
      name: 'Consult', 
      selector: row => (parseFloat(row.Consultation || 0) * consultationPer), 
      sortable: true,
      minWidth: '40px',
      maxWidth: dataColumnWdith,
    },
    { 
      name: 'Obs.', 
      selector: row => (parseFloat(row.Observation || 0) * observationPer), 
      sortable: true,
      minWidth: '40px',
      maxWidth: dataColumnWdith,
    },
    { 
      name: 'Surg.', 
      selector: row => (parseFloat(row.Surgery || 0) * surgeryPer), 
      sortable: true,
      minWidth: '40px',
      maxWidth: dataColumnWdith,
    },
    { 
      name: 'Ultra Sound', 
      selector: row => (parseFloat(row.Ultrasound || 0) * ultrasoundPer), 
      sortable: true,
      minWidth: '40px',
      maxWidth: dataColumnWdith,
    },
    { 
      name: 'Lab/Med', 
      selector: row => (parseFloat(row['Lab and Medication'] || 0) * labMedicationPer), 
      sortable: true,
      minWidth: '40px',
      maxWidth: dataColumnWdith,
    },
    { 
      name: 'Total', 
      selector: row => (
        (parseFloat(row.Consultation || 0) * consultationPer) +
        (parseFloat(row.Observation || 0) * observationPer) +
        (parseFloat(row.Surgery || 0) * surgeryPer) +
        (parseFloat(row.Ultrasound || 0) * ultrasoundPer) +
        (parseFloat(row['Lab and Medication'] || 0) * labMedicationPer)
      ), 
      sortable: true,
      minWidth: '40px',
      maxWidth: '100px',
    }
  ];

  const handlePrint = () => {
    window.print();
  };

  const handleBack = () => {
    navigate('/doctor-sales-summary');
  };

  return (
    <div className="doctor-sales-details">
      <div className="report-header">
        <img src={logo} alt="Logo" className="report-logo" />
        <button onClick={handleBack} className="close-doctor-sales-button">X</button>
      </div>

      <div className="report-title">
        <h2>Doctor Patient Visit Details</h2>
      </div>

      <div className="header-container">
        <table className="doctor-info-table">
          <tbody>
            <tr>
              <td><strong>Doctor:</strong></td>
              <td>{doctorName}</td>
            </tr>
            <tr>
              <td><strong>Pay Period:</strong></td>
              <td>{formatDateRange(startDate, endDate)}</td>
            </tr>
          </tbody>
        </table>

        <table className="earnings-summary-table">
          <thead>
            <tr>
              <th>Total Type</th>
              <th>Commission %</th>
              <th>Earnings</th>
            </tr>
          </thead>
          <tbody>
            {earningsTableData.map((row, index) => (
              <tr key={index}>
                <td>{row.label}</td>
                <td>{row.percentage}%</td>
                <td>{row.earnings}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="2"><strong>Total Earnings</strong></td>
              <td><strong>{calculateGrandTotal()}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="data-table-container">
        <DataTable
          columns={columns}
          data={salesDetails}
          highlightOnHover
          progressPending={isLoading}
          pagination={false} 
        />
      </div>

      <button onClick={handlePrint} className="btn btn-primary print-button">
        Print
      </button>
    </div>
  );
};

export default DoctorSalesDetails;
