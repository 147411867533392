import React, { useState, useEffect,useCallback } from 'react';
import axios from 'axios';
import '../styles/AdvancedArrayInput.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

 /**
 * AdvancedArrayInput component allows users to input and manage an array of items with additional fields.
 *
 * @param {Object} props - The component props.
 * @param {string} props.endpoint - The API endpoint to fetch options for the input field. Must return an array of objects with an 'item_name' and item_id property.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {Array} props.value - The initial value of the input field.
 * @param {Function} props.onChange - The callback function to handle changes to the input field.
 * @param {Array} props.additionalFields - The additional fields to be included in each item.
 */ 

const AdvancedArrayInput = ({ endpoint, label, name, value, onChange, additionalFields }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState(Array.isArray(value) ? value : []);

  const fetchOptions = useCallback(
    debounce(async (query) => {
      if (query) {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${endpoint}?query=${query}`);
        setOptions(response.data);
      }
    }, 300),
    [endpoint]
  );

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue, fetchOptions]);

  useEffect(() => {
    setSelectedItems(Array.isArray(value) ? value : []);
  }, [value]);

  const handleAddItem = () => {
    const selectedOption = options.find(option => option.item_name === inputValue);
    if (!selectedOption) {
      const userResponse = window.confirm(`Warning: The item "${inputValue}" does not exist. Possible matches: ${options.slice(0, 3).map(option => option.item_name).join(', ')}. Do you want to add the item any way?  Click Cancel to try again.`);
      if (!userResponse) {
        return;
      }
    }
    if (inputValue && !selectedItems.some(item => item.name === inputValue)) {
      const newItem = { 
        name: inputValue,
        item_id: selectedOption ? selectedOption.item_id : null,
        ...additionalFields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}) 
      };
      const newItems = [...selectedItems, newItem];
      setSelectedItems(newItems);
      onChange(name, newItems);
      setInputValue('');
    }
  };

  const handleFieldChange = (index, fieldName, fieldValue) => {
    const newItems = selectedItems.map((item, i) => i === index ? { ...item, [fieldName]: fieldValue } : item);
    setSelectedItems(newItems);
    onChange(name, newItems);
  };

  const handleRemoveItem = (index) => {
    const newItems = selectedItems.filter((_, i) => i !== index);
    setSelectedItems(newItems);
    onChange(name, newItems);
  };

  return (
    <div className="advanced-array-input">
      <label>Start typing to select items</label>
      <div className="input-group">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          list={`${name}-options`}
        />
        <datalist id={`${name}-options`}>
          {options.map((option, index) => (
            <option key={index} value={option.item_name} />
          ))}
        </datalist>
        <button type="button" onClick={handleAddItem}>
          Add
        </button>
      </div>
      <table className="advanced-array-table">
        <thead>
          <tr>
            <th className="nameCol">Name</th>
            {additionalFields.map((field) => (
              <th key={field.name} style={{ width: field.width }}>
                {field.label}
              </th>
            ))}
            <th>A</th>
          </tr>
        </thead>
        <tbody>
          {selectedItems.map((item, index) => (
            <tr key={index}>
              <td className="nameCol">
                {item.name}
                <br />
                <span className="missing-item-id-warning">
                  {!item.item_id ? '* Item not found!' : ''}
                </span>
              </td>
              {additionalFields.map((field) => (
                <td key={field.name}>
                  <input
                    type={field.type}
                    value={item[field.name]}
                    onChange={(e) => {
                      const value = field.type === 'number' && e.target.value < 0 ? 1 : e.target.value;
                      handleFieldChange(index, field.name, value);
                    }}
                  />
                </td>
              ))}
              <td>
                <button type="button" onClick={() => handleRemoveItem(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdvancedArrayInput;