import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClipboardList, faCogs, faFileInvoiceDollar,faChartBar,faUserTie, faPerson, faHospital, faBedPulse } from '@fortawesome/free-solid-svg-icons';
import '../../styles/LandingPage.css'; // Add styles here
import { useAuth } from '../../hooks/useAuth';


const PatientsDashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {rolePermissions } = useAuth();



function MyLink (props) { 
  const { path, iconname, name} = props;
  console.log("Permissions: ", rolePermissions);
  if(rolePermissions[props.path]?.View === 'true') {
    console.log("We have permission!");
   return (
  <Link to= {path}
        className="link-card">
    <FontAwesomeIcon icon={iconname} size="3x" className="icon"/><p>{name}</p>
  </Link>);
  }
};

  return (
        <div className="landing-page">

            <div className="links-container">
              <MyLink path="/patients" iconname={faPerson} name="Patients"/>
              <MyLink path="/patient-visits" iconname={faHospital} name="Visits"/>
              <MyLink path="/patient-admissions" iconname={faBedPulse} name="Admissions"/>
            </div>

        </div>
  );
};

export default PatientsDashboard;
