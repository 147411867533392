import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PatientVisitList from './PatientVisitList';
import PatientAdmissionList from './PatientAdmissionList';
import LabTestRequestList from '../Lab/LabTestRequestList';
import PatientBillingList from './PatientBillingList';
import '../../styles/PatientCard.css';
import { useAuth } from '../../hooks/useAuth';

const PatientCard = () => {
  const { state } = useLocation();
  const { patient } = state || {};
  const { hasPermission } = useAuth();

  if (!patient) {
    return <div>No patient information provided.</div>;
  }

  // State to track the active tab. Default is 'visits'.
  const [activeTab, setActiveTab] = useState('visits');

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'visits':
        return <PatientVisitList patient={patient} />;
      case 'admissions':
        return <PatientAdmissionList patient={patient} />;
      case 'labRequests':
        return <LabTestRequestList patient={patient} viewName='PatientCard' />;
      case 'billing':
        return <PatientBillingList patient={patient} />;
      default:
        return null;
    }
  };

  return (
    <div className="patient-card">
      <header className="patient-card-header">
        <h2>Patient Details</h2>
        <p><strong>Patient Number:</strong> {patient.patient_number}</p>
        <p>
          <strong>Name:</strong> {patient.patient_first_name} {patient.patient_last_name}
        </p>
        <p><strong>Date of Birth:</strong> {patient.patient_dob}</p>
        <p><strong>Patient Type:</strong> {patient.patient_type}</p>
        <p><strong>Company Name:</strong> {patient.company}</p>
        {/* Additional patient details can be added here */}
      </header>

      <nav className="patient-card-tabs">
        <ul>
          {hasPermission('/patient-card/Tabs/Visits', 'View') && (
            <li
              className={activeTab === 'visits' ? 'active' : ''}
              onClick={() => setActiveTab('visits')}
            >
              Visits
            </li>
          )}
          {hasPermission('/patient-card/Tabs/Admissions', 'View') && (
            <li
              className={activeTab === 'admissions' ? 'active' : ''}
              onClick={() => setActiveTab('admissions')}
            >
              Admissions
            </li>
          )}
          {hasPermission('/patient-card/Tabs/LabRequests', 'View') && (
            <li
              className={activeTab === 'labRequests' ? 'active' : ''}
              onClick={() => setActiveTab('labRequests')}
            >
              Lab Requests
            </li>
          )}
          {hasPermission('/patient-card/Tabs/Billing', 'View') && (
            <li
              className={activeTab === 'billing' ? 'active' : ''}
              onClick={() => setActiveTab('billing')}
            >
              Billing
            </li>
          )}
        </ul>
      </nav>

      <div className="patient-card-content">
        {renderActiveTab()}
      </div>
    </div>
  );
};

export default PatientCard;
