import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MedicalReportsList from './components/MedicalReportsList';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './hooks/useAuth';
import Header from './components/Header';
import UserManagement from './components/UserManagement';
import RolePermissions from './components/RolePermissions';
import LandingPage from './components/LandingPage';
import Account from './components/Account';
import SalesTransactions from './components/SalesTransactions';
import SalesForm from './components/SalesForm';
import SalesQueue from './components/SalesQueue';
import BulkInvoice from './components/BulkInvoice';
import DoctorSalesSummary from './components/DoctorSalesSummary';
import DoctorSalesDetails from './components/DoctorSalesDetails';
import CustomerAgingSummary from './components/CustomerAgingSummary';
import EmployeeSalaries from './components/EmployeeSalaries';
import EmployeePaymentList from './components/EmployeePaymentList';
import EmployeeList from './components/EmployeeList';
import MedicalContractList from './components/MedicalContractList';
import MedicalReportsDashboard from './components/MedicalReportsDashboard';
import TestDocumentAI from './components/testing/TestDocumentAI';
import PatientsDashboard from './components/Patients/PatientsDashboard';
import PatientList from './components/Patients/PatientList';
import PatientVisitList from './components/Patients/PatientVisitList';
import PatientAdmissionList from './components/Patients/PatientAdmissionList';
import LabTestDashboard from './components/Lab/LabTestDashboard';
import LabTestList from './components/Lab/LabTestList';
import LabTestRequestForm from './components/Lab/LabTestRequestForm';
import LabTestRequestList from './components/Lab/LabTestRequestList';
import LabTestRequestDetailForm from './components/Lab/LabTestRequestDetailForm';
import PatientCard from './components/Patients/PatientCard';
import LabTestReport from './components/Lab/LabTestReport';
import PatientBillingList from './components/Patients/PatientBillingList';
import PatientBillingForm from './components/Patients/PatientBillingForm';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/account" element={<Account />} />
          </Route>
          <Route path="/medical-reports" element={<PrivateRoute />}>
            <Route path="/medical-reports" element={<MedicalReportsList />} />
          </Route>
          <Route path="/sales-form" element={<PrivateRoute />}>
            <Route path="/sales-form" element={<SalesForm />} />
          </Route>
          <Route path="/sales-queue" element={<PrivateRoute />}>
            <Route path="/sales-queue" element={<SalesQueue />} />
          </Route>
          <Route path="/user-management" element={<PrivateRoute requiredRoles={['Admin']} requiredPermissions={['View']} />}>
            <Route path="/user-management" element={<UserManagement />} />
          </Route>
          <Route path="/role-management" element={<PrivateRoute requiredRoles={['Admin']} requiredPermissions={['View']} />}>
            <Route path="/role-management" element={<RolePermissions />} />
          </Route>
          <Route path="/sales-transactions" element={<PrivateRoute />}>
            <Route path="/sales-transactions" element={<SalesTransactions />} />
          </Route>
          <Route path="/customer-invoice" element={<PrivateRoute />}>
            <Route path="/customer-invoice" element={<BulkInvoice />} />
          </Route>
          <Route path="/doctor-sales-summary" element={<PrivateRoute />}>
            <Route path="/doctor-sales-summary" element={<DoctorSalesSummary/>} />
          </Route>
          <Route path="/doctor-sales-details" element={<PrivateRoute />}>
            <Route path="/doctor-sales-details" element={<DoctorSalesDetails/>} />
          </Route>
          <Route path="/customer-aging-summary" element={<PrivateRoute />}>
            <Route path="/customer-aging-summary" element={<CustomerAgingSummary/>} />
          </Route>
          <Route path="/employee-salaries" element={<PrivateRoute />}>
            <Route path="/employee-salaries" element={<EmployeeSalaries/>} />
          </Route>
          <Route path="/employee-payment-list" element={<PrivateRoute />}>
            <Route path="/employee-payment-list" element={<EmployeePaymentList/>} />
          </Route>
          <Route path="/employee-list" element={<PrivateRoute />}>
            <Route path="/employee-list" element={<EmployeeList/>} />
          </Route>
          <Route path="/medical-contract-list" element={<PrivateRoute />}>
            <Route path="/medical-contract-list" element={<MedicalContractList/>} />
          </Route>
          <Route path="/medical-reports-dashboard" element={<PrivateRoute />}>
            <Route path="/medical-reports-dashboard" element={<MedicalReportsDashboard/>} />
          </Route>
          <Route path="/patients-dashboard" element={<PrivateRoute />}>
            <Route path="/patients-dashboard" element={<PatientsDashboard/>} />
          </Route>
          <Route path="/patients" element={<PrivateRoute />}>
            <Route path="/patients" element={<PatientList/>} />
          </Route>
          <Route path="/patient-visits" element={<PrivateRoute />}>
            <Route path="/patient-visits" element={<PatientVisitList/>} />
          </Route>
          <Route path="/patient-admissions" element={<PrivateRoute />}>
            <Route path="/patient-admissions" element={<PatientAdmissionList/>} />
          </Route>
          <Route path="/labTests" element={<PrivateRoute />}>
            <Route path="/labTests" element={<LabTestList/>} />
          </Route>
          <Route path="/lab" element={<PrivateRoute />}>
            <Route path="/lab" element={<LabTestDashboard/>} />
          </Route>
          <Route path="/lab-request-form" element={<PrivateRoute />}>
            <Route path="/lab-request-form" element={<LabTestRequestForm/>} />
          </Route>
          <Route path="/lab-requests" element={<PrivateRoute />}>
            <Route path="/lab-requests" element={<LabTestRequestList/>} />
          </Route>
          <Route path="/lab-request-details" element={<PrivateRoute />}>
            <Route path="/lab-request-details" element={<LabTestRequestDetailForm/>} />
          </Route>
          <Route path="/patient-card" element={<PrivateRoute />}>
            <Route path="/patient-card" element={<PatientCard/>} />
          </Route>
          <Route path="/lab-report" element={<PrivateRoute />}>
            <Route path="/lab-report" element={<LabTestReport/>} />
          </Route>
          <Route path="/patient-billing" element={<PrivateRoute />}>
            <Route path="/patient-billing" element={<PatientBillingList/>} />
          </Route>
          <Route path="/patient-billing-form" element={<PrivateRoute />}>
            <Route path="/patient-billing-form" element={<PatientBillingForm/>} />
          </Route>
          <Route path="/testing/test-document-ai" element={<TestDocumentAI />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
