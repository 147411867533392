import React from 'react';
import DataList from '../DataList';
import { getFormattedDate } from '../../assets/Utils';
import ApiConfigFactory from '../../configurations/ApiConfigFactory';



const LabTestList = () => {
  // Initialize apiConfig using ApiConfigFactory
  const apiConfig = new ApiConfigFactory('lab/labTests', 'Lab Tests');
  apiConfig.defaultSortAsc = false;
  apiConfig.defaultSortFieldId = 'id';
  apiConfig.path = '/labTests';
  apiConfig.gcBucketIdentifier = '';//use default for the main patient object.
  // Configure fields, columns, and other properties
  apiConfig
    .addField({ name: 'id', label: 'ID', type: 'text', identifier: true, visible: false, tab: 'Main' })
    .addField({ name: 'category', label: 'Category', type: 'text', tab: 'Main' })
    .addField({ name: 'type', label: 'Type', type: 'text', required: true, tab: 'Main' })
    .addField({ name: 'test_name', label: 'Test Name', type: 'text', tab: 'Main' })
    .addField({ name: 'test_unit', label: 'Unit', type: 'text', required: false, tab: 'Main' })
    .addField({ name: 'test_reference_range', label: 'Range', type: 'text', required: true, tab: 'Main' })
    .addField({ name: 'lab_type', label: 'Laboratory/Radiology', type: 'dropdown', required: false, tab: 'Main' })
    .addField({ name: 'note', label: 'Note', type: 'text', tab: 'Main' })
    .addField({ name: 'item_id', label: 'Zoho Item Mapping', type: 'dropdown', tab: 'Admin' })
   
    .addStaticDropdownField({
      name: 'lab_type',
      options: [{ id: 'Laboratory', name: 'Laboratory' }, { id: 'Radiology', name: 'Radiology' }],
    })
    .addStaticDropdownField({
      name: 'patient_type',
      options: [{ id: 'Private', name: 'Private' }, { id: 'Company', name: 'Company' }],
    })
    .addDropdownField({
      name: 'item_id',
      endpoint: '/hospital-portal/api/items',
      valueField: 'item_id',
      labelField: 'item_name',
    })
    .addTab({ name: 'Main', roles: 'all', default: true })
    .addTab({ name: 'Admin', roles: 'Admin,Accountant' })
   
    .addBulkAction({ name: 'Generate Report', endpoint: '/hospital-portal/api/medical-contracts/generate-report' })

    .addColumn({ id: 'id', name: ' ID', selector: (row) => row.id, type: 'number', sortable: true, identifier: true, })
    .addColumn({ name: 'Category', selector: (row) => row.category, sortable: true, filterable: true, filterSelector: 'category' })
    .addColumn({ name: 'Type', selector: (row) => row.type, sortable: true, filterable: true, filterSelector: 'type' })
    .addColumn({ name: 'Test Name', selector: (row) => row.test_name, sortable: true, filterable: true, filterSelector: 'test_name' })
    .addColumn({ name: 'Note', selector: (row) => row.note, type: 'date', sortable: true })
    .addColumn( { name: 'Lab/Rdlgy', selector: (row) => row.lab_type, sortable: true, filterable: true, filterSelector: 'lab_type' })
    .addColumn( { name: 'Zoho Item', selector: (row) => row.item_id, sortable: true, filterable: true, filterSelector: 'item_id' })
  
  return <DataList apiConfig={apiConfig} columns={apiConfig.columns} />;
};

export default LabTestList;
