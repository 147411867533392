import React, { useState} from 'react';
import DataList from '../DataList';
import ApiConfigFactory from '../../configurations/ApiConfigFactory';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../styles/LabTestRequestList.css';
import { useAuth } from '../../hooks/useAuth';
import PatientBillingForm from './PatientBillingForm';
import '../../styles/PatientBillingList.css';

const PatientBillingList = () => {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const location = useLocation();
  const { hasPermission } = useAuth();
  // Extract the patient object from location state
  const { patient } = location.state || {};
  // Derive patient id from the patient object, if available
  const id = patient ? patient.patient_id : undefined;
  // Set a title using patient details if available
  const title = patient
    ? `Viewing Bills for ${patient.patient_first_name} ${patient.patient_last_name} - ${patient.patient_number}`
    : 'All Bills';

  // Initialize apiConfig using ApiConfigFactory with the patient id (if available)
  const apiConfig = new ApiConfigFactory('billing', title, 'patient_id', patient?.patient_id   );
  apiConfig.defaultSortAsc = false;
  apiConfig.defaultSortFieldId = 'id';
  apiConfig.path = '/patient-billing';
  apiConfig.allowAdd = false;
  apiConfig.allowDelete = false;
  apiConfig.onEdit = (row) => {
    setSelectedBill(row);
    setIsEditModalVisible(true);
  };


  // Configure fields, columns, and other properties
  apiConfig
    .addField({
      name: 'id',
      label: 'Bill ID',
      type: 'number',
      identifier: true,
      disabled: true,
      tab: 'Main'
    })
    .addField({
      name: 'patient_id',
      label: 'Patient ID',
      type: 'text',
      required: true,
      disabled: true,
      tab: 'Main',
      defaultValue: id
    })
    .addField({
      name: 'sales_number',
      label: 'Invoice/Sales Number',
      type: 'text',
      disabled: false,
      tab: 'Main'
    })
    .addField({
      name: 'date',
      label: 'Date',
      type: 'date',
      required: true,
      tab: 'Main',
      disabled: false
    })
    .addField({
      name: 'createdAt',
      label: 'Request Date',
      type: 'datetime',
      disabled: true,
      tab: 'Admin'
    })
    .addField({
      name: 'updatedBy',
      label: 'Updated By',
      type: 'text',
      disabled: true,
      tab: 'Admin'
    })
    .addField({
      name: 'zoho_sales_id',
      label: 'Zoho Sales ID',
      type: 'text',
      disabled: true,
      tab: 'Admin'
    });

    apiConfig
    .addTab({ name: 'Main', roles: 'all', default: true })
    .addTab({ name: 'Admin', roles: 'Admin' })
    
    apiConfig
    .addColumn({
      id: 'id',
      name: 'Bill ID',
      selector: (row) => row.id,
      type: 'number',
      identifier: true
    })
    .addColumn({
      name: 'Patient Number',
      selector: (row) => row.Patient.patient_number,
      sortable: true,
      filterable: true,
      filterSelector: 'Patient.patient_number'
    })
    .addColumn({
      name: 'Patient Name',
      selector: (row) => `${row.Patient?.patient_first_name} ${row.Patient?.patient_last_name}`,
      sortable: true
    })
    .addColumn({
      name: 'Sales Number',
      selector: (row) => row.sales_number,
      sortable: true,
      filterable: true,
      filterSelector: 'sales_number'
    })
    .addColumn({
        name: 'Visit ID',
        selector: (row) => row.visit_id,
        sortable: true
      })
      .addColumn({
        name: 'Lab Request ID',
        selector: (row) => row.lab_request_id,
        sortable: true
      })
      .addColumn({
        name: 'Radiology Request ID',
        selector: (row) => row.radiology_request_id,
        sortable: true
      })
    .addColumn({
      name: 'Bill Date',
      selector: (row) => row.date,
      type: 'date',
      sortable: true
    });
    /*
    .addColumn({
      name: 'Report',
      type: 'link',
      label: 'View Report',
      path: '/lab-report',
      getState: (row) => ({
        id: row.id,         // The request id
        patient: patient    // The patient object
      }),
      visible: (row) => hasPermission('/lab-report', 'View') && row.status === 'complete'
    })
    .addColumn({
      name: 'Lab Actions',
      type: 'link',
      label: 'Input Results',
      path: '/lab-request-details',
      getState: (row) => ({ request: row }),
      visible: (row) => hasPermission('/lab-request-detail', 'View') && (row.Patient.patient_type === 'Company' || (row.Patient.patient_type === 'Private' && (row.status === 'paid' || row.status === 'complete')))
    });
*/
  return (
    <div className="patient-list-container">
      {patient && hasPermission('/patient-billing', 'View') && (
        <div className="lab-request-link">
          <button
             onClick={() => setIsEditModalVisible(true)}
            className="create-bill-button"
          >
          {hasPermission('/patient-billing', 'Add') ? 'Create': 'View'}  New Bill
          </button>
        </div>
      )}
      <DataList apiConfig={apiConfig} columns={apiConfig.columns} key= {refreshKey} />
      {isEditModalVisible && (
    <div className="modal-wrapper">
        <PatientBillingForm
          onClose={() => {
            setIsEditModalVisible(false);
            setSelectedBill(null);
            setRefreshKey((prev) => prev + 1);
          }}
            patient={patient}
            bill={selectedBill}
        />
    </div>
      )}
    </div>
  );
};

export default PatientBillingList;
