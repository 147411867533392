import React from 'react';
import DataList from './DataList';
import { getFormattedDate } from '../assets/Utils';


const EmployeePaymentList = () => {
    
    const generatePayPeriodOptions = () => {
        const options = [];
        const currentDate = new Date();
      
        // Loop from 3 months in the past to 3 months in the future
        for (let i = -3; i <= 3; i++) {
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
          const year = date.getFullYear();
          const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
          options.push({ id: `${year} ${month}`, name: `${year} ${month}` });
        }
      console.log(options);
        return options;
      };
    const PayPeriodOptions = generatePayPeriodOptions();      
    
    
    const apiConfig = {
      title: 'Employee Payment',
      path: '/employee-payment-list',
      fetchEndpoint: '/hospital-portal/api/employees/payments',
      createEndpoint: '/hospital-portal/api/employees/payments',
      updateEndpoint: '/hospital-portal/api/employees/payments',
      deleteEndpoint: '/hospital-portal/api/employees/payments',
      defaultSortFieldId: 'payment_date',
      defaultSortAsc:false,
      allowAdd: true,
      conditionalStyles: {
        "Status": {
            "type": "text",
            "conditions": {
              "matches": {
                  "Processing": {"color": "black","backgroundColor":"yellow"},
                  "Request Failed": {"color": "white","backgroundColor":"red"}
              },
                "default": {"color":"green","backgroundColor":"white"}
            }
          }
      },
      bulkActions: [
        { name: 'Create Expense', endpoint: '/hospital-portal/api/employees/payments/salary-expense/create' },
      ],
      dropdownFields: [
        {
          name: 'employee_id',
          endpoint: '/hospital-portal/api/employees',
          valueField: 'employee_id',
          labelField: 'employee_name',
        },
        {
            name: 'paid_through_account',
            endpoint: '/hospital-portal/api/employees/bank-accounts/paid-through-accounts',
            valueField: 'account_number',
            labelField: 'account_name'
        }
      ],
      staticDropdownFields: [
        {
            name: 'payment_reason',
            options: [
                { id: 'Salary', name: 'Salary' },
                { id: 'Annual Pay', name: 'Annual Pay' },
                { id: 'Settlement', name: 'Settlement' },

            ],
        },
        {
            name: 'pay_period',
            options: PayPeriodOptions,
        }
        ],
      fields: [
        { name: 'payment_id', label:'Payment ID', type:'text', identifier:true, disabled:true,tab:'Payment' },
        { name: 'employee_id', label: 'Employee', type: 'dropdown',tab:'Payment',required:true},
        { name: 'payment_date', label: 'Payment Date', type: 'date', defaultValue: getFormattedDate() ,tab:'Payment',required:true},
        { name: 'payment_amount', label: 'Amount', type: 'number',tab:'Payment',required:true },
        { name: 'pay_period', label: 'Pay Period', type: 'dropdown',tab:'Payment',required:true },
        { name: 'paid_through_account', label: 'Paid Through Account', type: 'dropdown',tab:'Payment',required:true },
        { name: 'check_number', label: 'Check Number', type: 'text', tab:'More' },
        { name: 'payment_reason', label: 'Payment Reason', type: 'dropdown', defaultValue: 'Salary',tab:'Payment',required:true },
        { name: 'note', label: 'Note', type: 'textarea',tab:'More',maxRows: 7 },
        {name: 'expense_created', label: 'Expense Created', type: 'checkbox',tab:'Admin'},
        {name: 'expense_record_id', label: 'Expense ID', type: 'text',tab:'Admin'},
        {name: 'status', label: 'Status', type: 'text',tab:'Admin', defaultValue:'New'},
      ],
      tabs: [
        {name: 'Payment', roles: 'all',default:true},
        {name: 'More', roles: 'Admin,Accountant'},
        {name: 'Admin', roles: 'Admin'}
      ]
    };
  
    const columns = [
      { name: 'ID', selector: (row) => row.payment_id, type:'integer', sortable: true,identifier:true,filterable: true, filterSelector: 'payment_id',width:'50px' },  
      { name: 'Employee Name', selector: (row) => row.Employee.employee_name, sortable: true,filterable: true,filterSelector: 'Employee.employee_name' },
      { id: 'payment_date', name: 'Payment Date', selector: (row) => row.payment_date, sortable: true },
      { name: 'Amount', selector: (row) => row.payment_amount, sortable: true },
      { name: 'Pay Period', selector: (row) => row.pay_period, sortable: true, filterable: true, filterSelector: 'pay_period' },
      { name: 'Payment Type', selector: (row) => row.payment_reason, sortable: true, filterable: true, filterSelector: 'payment_reason' },
      { name: 'Status', selector: (row) => row.status, sortable: true}
    ];
   /* 
    const exportColumns = [
        { name: 'ID', exportSelector: 'payment_id'},  
        { name: 'Employee Name', exportSelector: 'Employee.employee_name'},
        { name: 'Payment Date', exportSelector: 'payment_date'},
        { name: 'Amount', exportSelector:'payment_amount'},
        { name: 'Pay Period', exportSelector:'pay_period'},
        { name: 'Expense Created?', exportSelector: 'expense_created'},    
    ]*/

    
  
    return <DataList apiConfig={apiConfig} columns={columns} />;
  };
  
  export default EmployeePaymentList;