import React, { useState, useEffect } from 'react';
import DataList from './DataList';
import { getFormattedDate } from '../assets/Utils';
import conditionalStyles from '../styles/MedicalReportsList.json';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const MedicalReportsList = () =>  {
  const [contract, setContract] = useState([]);
  const [contractColumns, setContractColumns] = useState([]);
  const [contractFields, setContractFields] = useState([]);
  useEffect(() => {
    fetchDataSupression();

  }, []);
  
  const location = useLocation();
  const { name } = location.state || {}; 
  const { id } = location.state || {};
  console.log("State: ",location.state);
  const optionsDoubtful = [{id:'Doubtful',name:'Doubtful'},{id:'Normal',name:'Normal'}];
  const optionsShown = [{id:'Shown',name:'Shown'},{id:'Not Shown',name:'Not Shown'}];
  const optionsPositive = [{id:'Positive',name:'Positive'},{id:'Negative',name:'Negative'}];
  const optionsRecommended = [{id:'Recommended',name:'Recommended'},{id:'Negative',name:'Negative'}];

//replace default Fields with what is set in Medical Contractts for this customer

  const fields = contractFields?contractFields:[
    { name:'id', label: 'ID', type: 'number', identifier:true, disabled:true},
    { name: 'shId', label: 'SH ID', type:'text', required: true,tab: 'Patient' },
    { name: 'name', label: 'Patient Name', type: 'text', required: true, tab: 'Patient' },
    { name: 'serialNumber', label: 'Employee S/N', type: 'text', required: true, tab: 'Patient' },
    { name: 'customer', label: 'Customer', type: 'text', required: true, tab: 'Patient', defaultValue: name, disabled:true },
    { name: 'batchName', label: 'Batch Name', type: 'dropdown', required: true, tab: 'Patient' },
    { name: 'bloodSugar', label: 'Blood Sugar', type: 'number', tab: 'Test Results' },
    { name: 'hba1', label: 'HBA1', type: 'number', tab: 'Test Results' },
    { name: 'kidneyFunctionTest', label: 'Kidney Function Test', type: 'dropdown', tab: 'Test Results', defaultValue: 'Normal' },
    { name: 'liverFunctionTest', label: 'Liver Function Test', type: 'dropdown', tab: 'Test Results' },
    { name: 'cholesterolProfile', label: 'Cholesterol Profile', type: 'dropdown', tab: 'Test Results' },
    { name: 'hb', label: 'HB', type: 'number', tab: 'Test Results' },
    { name: 'tuberculosis', label: 'Tuberculosis', type: 'dropdown', tab: 'Test Results' },
    { name: 'rvs', label: 'RVS', type: 'dropdown', tab: 'Test Results' },
    { name: 'chestXRay', label: 'Chest X-Ray', type: 'dropdown', tab: 'Test Results' },
    { name: 'ecg', label: 'ECG', type: 'dropdown', tab: 'Test Results' },
    { name: 'hbsAg', label: 'HBS/AG', type: 'dropdown', tab: 'Test Results' },
    { name: 'hcv', label: 'HCV', type: 'dropdown', tab: 'Test Results' },
    { name: 'psaQuantitative', label: 'PSA Quantitative', type: 'number', tab: 'Test Results' },
    { name: 'urineAnalysis', label: 'Urine Analysis', type: 'dropdown', tab: 'Test Results' },
    { name: 'audiometryTest', label: 'Audiometry Test', type: 'dropdown', tab: 'Test Results' },
    { name: 'labFile', label: 'Lab Report', type: 'file', tab: 'Lab Reports', accept: 'application/pdf'},
    { name: 'cbcFile', label: 'CBC Report', type: 'file', tab: 'Lab Reports', accept: 'application/pdf'},
    { name: 'urineFile', label: 'Urine Report', type: 'file', tab: 'Lab Reports', accept: 'application/pdf'},
    { name: 'ecgFile', label: 'ECG', type: 'file', tab: 'ECG / X-Ray', accept: 'image/jpeg,image/png'},
    { name: 'xRayFile', label: 'X-Ray', type: 'file', tab: 'ECG / X-Ray',accept: 'image/jpeg,image/png'}
  ]

  const apiConfig = {
    title: `${name} - Employee Medicals`,
    path: '/medical-reports',
    fetchEndpoint: '/hospital-portal/api/medicalreports',
    createEndpoint: '/hospital-portal/api/medicalreports',
    updateEndpoint: '/hospital-portal/api/medicalreports',
    deleteEndpoint: '/hospital-portal/api/medicalreports',
    defaultSortFieldId: 'id',
    defaultSortAsc:false,
    conditionalStyles,
    gcBucketName: 'sh-medicals-upload-us', //file storage
    systemFilters: [{ name: 'customer', value: name},],
    bulkActions: [
      { name: 'Extract Data From Files', endpoint: '/hospital-portal/api/documents/lab-results/process-pdf' },
    ],
    dropdownFields: [
      {
        name: 'batchName',
        endpoint: `/hospital-portal/api/medicalreports/medical-contract/customerBatches/${name}`,
        valueField: 'batch',
        labelField: 'batch',
      },
    ],
    staticDropdownFields: [
      { name: 'kidneyFunctionTest', options: optionsDoubtful },
      { name: 'liverFunctionTest', options: optionsDoubtful },
      { name: 'cholesterolProfile', options: optionsDoubtful },
      { name: 'rvs', options: optionsRecommended },
      { name: 'chestXRay', options: optionsDoubtful },
      { name: 'ecg', options: optionsDoubtful },
      { name: 'hbsAg', options: optionsPositive },
      { name: 'hcv', options: optionsPositive },
      { name: 'urineAnalysis', options: optionsDoubtful },
      { name: 'audiometryTest', options: optionsDoubtful },
      { name: 'tuberculosis', options: optionsShown},

      ],
      fields: fields,
    tabs: [
      {name: 'Patient', roles: 'all',default:true},
      {name: 'Test Results', roles: 'all'},
      {name: 'Lab Reports', roles: 'all'},
      {name: 'ECG / X-Ray', roles: 'all'},
      {name: 'Doctor', roles:'Accountant,Doctor,Admin'},
      {name: 'Admin', roles: 'Admin'},

    ]
  };


//replace default columns with what is set in Medical Contractts for this customer
const columns2 = contractColumns.map((col) => ({...col,selector: (row) => row[col.selectorKey]}));

  const columns =  columns2? columns2:[
    { name: 'ID', selector: (row) => row.id, type:'integer', sortable: true,identifier:true,width:'50px' }, 
    { name: 'Name', selector: (row) => row.name, sortable: true, filterable: true, filterSelector: 'name' },
    { name: 'Customer', selector: (row) => row.customer, sortable: true },
    { name: 'Batch Name', selector: (row) => row.batchName, sortable: true, filterable: true, filterSelector: 'batchName' },
    { name: 'Blood Sugar', selector: (row) => row.bloodSugar, sortable: true },
    { name: 'HBA1', selector: (row) => row.hba1, sortable: true },
    { name: 'Kidney Function', selector: (row) => row.kidneyFunctionTest, sortable: true },
    { name: 'Liver Function', selector: (row) => row.liverFunctionTest, sortable: true },
    { name: 'Cholesterol Profile', selector: (row) => row.cholesterolProfile, sortable: true },
    { name: 'HB', selector: (row) => row.hb, sortable: true },
    { name: 'Tuberculosis', selector: (row) => row.tuberculosis, sortable: true },
    { name: 'RVS', selector: (row) => row.rvs, sortable: true },
    { name: 'Chest X-Ray', selector: (row) => row.chestXRay, sortable: true },
    { name: 'ECG', selector: (row) => row.ecg, sortable: true },
    { name: 'HBsAg', selector: (row) => row.hbsAg, sortable: true },
    { name: 'HCV', selector: (row) => row.hcv, sortable: true },
    { name: 'PSA Quantitative', selector: (row) => row.psaQuantitative, sortable: true },
    { name: 'Urine Analysis', selector: (row) => row.urineAnalysis, sortable: true },
    { name: 'Audiometry Test', selector: (row) => row.audiometryTest, sortable: true },
  ];

  const fetchDataSupression = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/medicalreports/medical-contract/form/${id}`, {
       
      });
      setContract(response.data);
      const rawColsData = response.data.columns_config.trim();
      const rawFieldsData = response.data.fields_config.trim();
      const cols =  JSON.parse(rawColsData);
      const rows =  JSON.parse(rawFieldsData);
      setContractColumns(cols);
      setContractFields(rows);
      //console.log('Data: ',cols);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };


  return <DataList apiConfig={apiConfig} columns={columns} />;
};

export default MedicalReportsList;
