import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../../styles/LabTestReport.css';
import logo from '../../assets/logo192.png';
import { getFormattedDateTime } from '../../assets/Utils';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const LabTestReport = () => {
  // Retrieve id and patient from navigation state.
  const { id, patient } = useLocation().state || {};
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const cbcUnitsMap = {
    hemoglobin: 'g/dL',
    'rbc – erythrocytes': 'x10⁶/uL',
    'hct – hematocrit': '%',
    mcv: 'fL',
    mch: 'pg',
    mchc: 'g/dL',
    rdwcv: '%',
    platelets: 'x10³/uL',
    mpv: 'fL',
    'pdw-cv': '%',
    'wbcs – leucocytes': 'x10³/uL',
    'neutrophil %': '%',
    'lymphocytes %': '%',
    'monocytes %': '%',
    'eosinophil %': '%',
    'basophil %': '%',
    'pct – plateletcrit': '%',
    'lymphocyte #': 'x10³/uL',
    'monocyte #': 'x10³/uL',
    'neutrophil #': 'x10³/uL',
    'eosinophil #': 'x10³/uL',
    'basophil #': 'x10³/uL',
    'wbc': '10^9/L',
    'lym#': '10^9/L',
    'mid#': '10^9/L',
    'gra#': '10^9/L',
    'rbc': '10^12/L',
    'hgb': 'g/L',
    'hct': '%',
    'mcv': 'fL',
    'mch': 'pg',
    'mchc': 'g/L',
    'rdw-cv': '%',
    'plt': '10^9/L',
    'mpv': 'fL',
    'pdw': '%',
    'pct': '%',
    'lym%': '%',
    'mid%': '%',
    'gra%': '%',
    'p-lcr': '%',
    'p-lcc': '10^9/L',
  };

  // Helper to check range flag.
  const checkRangeFlag = (valueStr, rangeStr) => {
    if (!rangeStr || !valueStr) return '';
    const match = rangeStr.match(/([\d.]+)\s*-\s*([\d.]+)/);
    if (!match) return '';
    const minVal = parseFloat(match[1]);
    const maxVal = parseFloat(match[2]);
    const val = parseFloat(valueStr);
    if (isNaN(minVal) || isNaN(maxVal) || isNaN(val)) return '';
    if (val < minVal) return 'L';
    if (val > maxVal) return 'H';
    return '';
  };

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }
    const fetchReportDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/lab-request-details/${id}`
        );
        setReport(response.data);
      } catch (error) {
        console.error('Error fetching report details:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchReportDetails();
  }, [id]);

  if (isLoading) {
    return (
      <div className="lab-test-report printable">
        <p>Loading report details...</p>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="lab-test-report printable">
        <p>No report data available.</p>
      </div>
    );
  }

  // Use report.request as header; inject patient if missing.
  const reportHeader = report.request || {};
  if (!reportHeader.Patient && patient) {
    reportHeader.Patient = patient;
  }
  const details = report.details || [];

  // Separate out the CBC detail ("Attach CBC File") from the other details.
  const cbcDetail = details.find(
    (detail) =>
      detail.MedicalLabTest.test_name.toLowerCase() === 'full blood count'
  );
  const nonCbcDetails = details.filter(
    (detail) =>
      detail.MedicalLabTest.test_name.toLowerCase() !== 'full blood count'
  );

  let cbcData = null;
  if (cbcDetail && cbcDetail.result) {
    try {
      cbcData = JSON.parse(cbcDetail.result);
    } catch (error) {
      console.error('Error parsing CBC result JSON:', error);
    }
  }

  const getUnit = (testName) => {
    const normalized = testName.toLowerCase().replace(/[^\w]+/g, '');
    let matchedKey = Object.keys(cbcUnitsMap).find(
      (k) => k.replace(/[^\w]+/g, '') === normalized
    );
    return matchedKey ? cbcUnitsMap[matchedKey] : '';
  };

  // Group non-CBC details by category and then by type.
  const groupedNonCbc = nonCbcDetails.reduce((acc, detail) => {
    const cat = detail.MedicalLabTest.category || 'Uncategorized';
    const typ = detail.MedicalLabTest.type || 'Other';
    if (!acc[cat]) acc[cat] = {};
    if (!acc[cat][typ]) acc[cat][typ] = [];
    acc[cat][typ].push(detail);
    return acc;
  }, {});

  // Action button handlers.
  const handlePrint = () => {
    window.print();
  };
  
  const handleDownloadPDF = async () => {
    // Temporarily hide action buttons.
    const actionButtons = document.querySelector('.action-buttons');
    const originalDisplay = actionButtons ? actionButtons.style.display : '';
    if (actionButtons) {
      actionButtons.style.display = 'none';
    }
  
    // Get the entire report container and the CBC section.
    const reportElement = document.querySelector('.lab-test-report');
    const cbcElement = document.querySelector('.cbc-section');
  
    // Hide CBC section from the main report capture.
    if (cbcElement) {
      cbcElement.style.display = 'none';
    }
  
    // Capture the main report (without CBC).
    const mainCanvas = await html2canvas(reportElement);
    const mainImgData = mainCanvas.toDataURL('image/png');
  
    // Restore CBC section so we can capture it separately.
    if (cbcElement) {
      cbcElement.style.display = 'block';
    }
    const cbcCanvas = cbcElement ? await html2canvas(cbcElement) : null;
    const cbcImgData = cbcCanvas ? cbcCanvas.toDataURL('image/png') : null;
  
    // Restore action buttons display.
    if (actionButtons) {
      actionButtons.style.display = originalDisplay;
    }
  
    // Create a new PDF document.
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
  
    // Function to add an image to the PDF (handles scaling).
    const addImageToPDF = (imgData, canvas) => {
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pageWidth;
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    };
  
    // Add the main report image.
    addImageToPDF(mainImgData, mainCanvas);
  
    // Add a new page and insert the CBC section if available.
    if (cbcImgData) {
      pdf.addPage();
      addImageToPDF(cbcImgData, cbcCanvas);
    }
  
    pdf.save(`LabReport_${reportHeader.id}.pdf`);
  };

  return (
    <div className="lab-test-report printable">
      {/* Action Buttons: hidden on print */}
      <div className="action-buttons no-print">
        <button className="btn-print" onClick={handlePrint}>
          Print Report
        </button>
        <button className="btn-download" onClick={handleDownloadPDF}>
          Download PDF
        </button>
      </div>

      <header className="report-header">
        <div className="lab-logo">
          <img src={logo} alt="Lab Logo" />
        </div>
        <div className="header-compact">
          <div className="lab-info">
            <h1>Shuman Hospital Laboratory</h1>
            <p>15/17 Pultney Street, Cotton Tree</p>
            <p>Phone: (+232) 090 33 00 00</p>
          </div>
          <div className="patient-compact">
            <p>
              <strong>ID:</strong> {reportHeader.Patient?.patient_id || 'N/A'}
            </p>
            <p>
              <strong>Name:</strong>{' '}
              {reportHeader.Patient
                ? `${reportHeader.Patient.patient_first_name} ${reportHeader.Patient.patient_last_name}`
                : 'N/A'}
            </p>
            <p>
              <strong>DOB:</strong> {reportHeader.Patient?.patient_dob || 'N/A'}
            </p>
          </div>
          <div className="report-meta">
            <p>
              <strong>Report ID:</strong> {reportHeader.id || 'N/A'}
            </p>
            <p>
              <strong>Date:</strong> {getFormattedDateTime(reportHeader.requestDate) || 'N/A'}
            </p>
          </div>
        </div>
      </header>

      <section className="test-results">
        <h2>{reportHeader.request_type ? reportHeader.request_type : 'Lab'} Report</h2>
        {Object.entries(groupedNonCbc).map(([category, typesObj]) => (
          <div key={category} className="test-category-section">
            <h3 className="category-header">{category}</h3>
            {Object.entries(typesObj).map(([type, detailsArr]) => (
              <div key={type} className="test-type-section">
                <h4 className="type-header">{type}</h4>
                {reportHeader.request_type && reportHeader.request_type.toLowerCase() === 'radiology' ? (
                  <table className="results-table">
                    <thead>
                      <tr>
                        <th>Test</th>
                        <th>Result</th>
                        <th>Indications</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailsArr.map((detail) => {
                        const testName = detail.MedicalLabTest.test_name;
                        const testResult = detail.result;
                        const indications = detail.result_note || '';
                        return (
                          <tr key={detail.id}>
                            <td>{testName}</td>
                            <td>{testResult}</td>
                            <td>{indications}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <table className="results-table">
                    <thead>
                      <tr>
                        <th>Test</th>
                        <th>Result</th>
                        <th>Units</th>
                        <th>Reference Range</th>
                        <th>Indicator</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailsArr.map((detail) => {
                        const testName = detail.MedicalLabTest.test_name;
                        const testResult = detail.result;
                        const unit = detail.MedicalLabTest.test_unit || '';
                        const refRange = detail.MedicalLabTest.test_reference_range || 'N/A';
                        const flag = checkRangeFlag(testResult, refRange);
                        let flagStyle = {};
                        if (flag === 'L') flagStyle = { color: 'blue' };
                        if (flag === 'H') flagStyle = { color: 'red' };
                        return (
                          <tr key={detail.id}>
                            <td>{testName}</td>
                            <td>{testResult}</td>
                            <td>{unit}</td>
                            <td>{refRange}</td>
                            <td style={flagStyle}>{flag}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            ))}
          </div>
        ))}

        {cbcData && (
          <section className="cbc-section">
            <h2>Complete Blood Count</h2>
            <table className="results-table cbc-table">
              <thead>
                <tr>
                  <th>Test Name</th>
                  <th>Test Value</th>
                  <th>Units</th>
                  <th>Range</th>
                  <th>Indicator</th>
                </tr>
              </thead>
              <tbody>
                {cbcData.map((row, idx) => {
                  const unit = getUnit(row.testName);
                  const flag = checkRangeFlag(row.testValue, row.range);
                  let flagStyle = {};
                  if (flag === 'L') flagStyle = { color: 'blue' };
                  if (flag === 'H') flagStyle = { color: 'red' };
                  return (
                    <tr key={idx}>
                      <td>{row.testName}</td>
                      <td>{row.testValue}</td>
                      <td>{unit}</td>
                      <td>{row.range}</td>
                      <td style={flagStyle}>{flag}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        )}
      </section>

      {/* Findings Section - only for Radiology */}
      {reportHeader.request_type &&
        reportHeader.request_type.toLowerCase() === 'radiology' &&
        reportHeader.findings && (
          <section className="findings-section">
            <h3>Findings</h3>
            <p>{reportHeader.findings}</p>
          </section>
        )}

      <footer className="report-footer">
        <p>
          This document is intended for informational purposes only and should
          be interpreted by a qualified healthcare professional.
        </p>
        <p className="signature-line"></p>
      </footer>
    </div>
  );
};

export default LabTestReport;
