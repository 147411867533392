class ApiConfigFactory {
    /**
   * @param {string} serverRoute - The base route for the API (e.g., 'patients', 'visits').
   * @param {string} title - The human-readable title for the configuration.
   * @param {string} [parentIDName=null] - The name of the parent ID parameter.
   * @param {number|string} [parentIDValue=null] - The value of the parent ID parameter.
   */
  constructor(serverRoute, title, parentIDName = null, parentIDValue = null) {
    this.serverRoute = serverRoute;
    this.title = title;
    this.formTitle = '';
    this.baseEndpoint = `/hospital-portal/api/${serverRoute}`;
    this.fetchEndpoint = `${this.baseEndpoint}`;
    this.createEndpoint = `${this.baseEndpoint}`;
    this.updateEndpoint = `${this.baseEndpoint}`;
    this.deleteEndpoint = `${this.baseEndpoint}`;
    this.defaultSortFieldId = 'id';
    this.defaultSortAsc = false;
    this.fields = [];
    this.columns = [];
    this.tabs = [];
    this.dropdownFields = [];
    this.staticDropdownFields = [];
    this.bulkActions = [];
    this.conditionalStyles = {};
    this.useLocalCaching = false;
    this.systemFilters = [];
    this.gcBucketName = 'sh-patient-files';
    this.gcBucketIdentifier = serverRoute.includes('patients')?'patient_id':'';
    this.allowAdd = true;
    this.allowEdit = true;
    this.allowDelete = true;

    // Append parent ID to fetchEndpoint, if provided
    if (parentIDName && parentIDValue) {
      this.appendQueryParam(parentIDName, parentIDValue);
    }
  }

  // Add a form field
  addField(field) {
    this.fields.push(field);
    return this;
  }

  // Add a data table column
  addColumn(column) {
    this.columns.push(column);
    return this;
  }

  // Add a tab for form organization
  addTab(tab) {
    this.tabs.push(tab);
    return this;
  }

  // Add a dropdown field
  addDropdownField(field) {
    this.dropdownFields.push(field);
    return this;
  }

  // Add a static dropdown field
  addStaticDropdownField(field) {
    this.staticDropdownFields.push(field);
    return this;
  }

  // Add bulk actions
  addBulkAction(action) {
    this.bulkActions.push(action);
    return this;
  }

  // Set endpoints dynamically
  setEndpoints({ fetch, create, update, delete: del }) {
    if (fetch) this.fetchEndpoint = fetch;
    if (create) this.createEndpoint = create;
    if (update) this.updateEndpoint = update;
    if (del) this.deleteEndpoint = del;
    return this;
  }

  // Append query parameters to fetchEndpoint
  appendQueryParam(key, value) {
    const separator = this.fetchEndpoint.includes('?') ? '&' : '?';
    this.fetchEndpoint = `${this.fetchEndpoint}${separator}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    return this; // Allow method chaining
  }

  // Set conditional styling for a column
  addConditionalStyle(columnName, conditions) {
    this.conditionalStyles[columnName] = conditions;
    return this;
  }
}

export default ApiConfigFactory;
