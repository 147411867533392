import React from 'react';
import DataList from '../DataList';
import { getFormattedISODateTime } from '../../assets/Utils';
import { useLocation } from 'react-router-dom';
import ApiConfigFactory from '../../configurations/ApiConfigFactory';

const PatientAdmissionList = () => {
  const location = useLocation();
  // Extract the patient object from the location state.
  const { patient } = location.state || {};
  // If a patient exists, derive the id.
  const id = patient ? patient.patient_id : undefined;
  
  // Set a title based on the patient information.
  const title = patient
    ? `Admissions for Patient: ${patient.patient_first_name} ${patient.patient_last_name} - ${patient.patient_number}`
    : 'All Admissions';

  // Initialize apiConfig using ApiConfigFactory
  const apiConfig = new ApiConfigFactory('patients/admissions', title, 'patient_id', id);
  apiConfig.allowAdd = id ? true : false;
  apiConfig.path = '/patient-admissions';

  // Configure fields, columns, and other properties
  apiConfig
    .addField({
      name: 'admission_id',
      label: 'Admission ID',
      type: 'number',
      identifier: true,
      disabled: true,
      tab: 'Admission Details'
    })
    .addField({
      name: 'patient_id',
      label: 'Patient ID',
      type: 'number',
      tab: 'Admission Details',
      defaultValue: id,
      visible: false
    })
    .addField({
      name: 'admission_date',
      label: 'Admission Date',
      type: 'datetime-local',
      required: true,
      tab: 'Admission Details',
      defaultValue: getFormattedISODateTime()
    })
    .addField({
      name: 'branch',
      label: 'Branch',
      type: 'dropdown',
      required: true,
      tab: 'Admission Details'
    })
    .addField({
      name: 'ward',
      label: 'Ward',
      type: 'dropdown',
      required: true,
      tab: 'Admission Details'
    })
    .addField({
      name: 'bed',
      label: 'Bed',
      type: 'text',
      required: false,
      tab: 'Admission Details'
    })
    .addField({
      name: 'discharge_date',
      label: 'Discharge Date',
      type: 'datetime-local',
      required: false,
      tab: 'Transfer/Discharge'
    })
    .addField({
      name: 'transfer_date',
      label: 'Transfer Date',
      type: 'datetime-local',
      required: false,
      tab: 'Transfer/Discharge'
    })
    .addField({
      name: 'transfer_location',
      label: 'Transfer Location',
      type: 'text',
      required: false,
      tab: 'Transfer/Discharge'
    })
    .addField({
      name: 'admissionReason',
      label: 'Admission Reason',
      type: 'textarea',
      required: false,
      tab: 'Admission Details',
      maxRows: 5
    })
    .addField({
      name: 'admissionFiles',
      label: 'Admission Files',
      type: 'file',
      required: false,
      tab: 'Files'
    })
    .addStaticDropdownField({
      name: 'branch',
      options: [
        { id: 'Poultney', name: 'Poultney' },
        { id: 'Kissy', name: 'Kissy' }
      ]
    })
    .addStaticDropdownField({
      name: 'ward',
      options: [
        { id: 'Children', name: 'Children' },
        { id: 'Adult', name: 'Adult' }
      ]
    })
    .addTab({
      name: 'Admission Details',
      roles: 'all',
      default: true
    })
    .addTab({
      name: 'Transfer/Discharge',
      roles: 'all'
    })
    .addTab({
      name: 'Files',
      roles: 'all'
    })
    .addBulkAction({
      name: 'Generate Report',
      endpoint: '/hospital-portal/api/patient-admissions/generate-report'
    })
    .addColumn({
      id: 'admission_id',
      name: 'Admission ID',
      selector: (row) => row.admission_id,
      type: 'number',
      sortable: true,
      identifier: true,
      width: '100px',
      filterable: true,
      filterSelector: 'admission_id'
    })
    .addColumn({
      name: 'First Name',
      selector: (row) => row.Patient.patient_first_name,
      type: 'text',
      sortable: true,
      filterable: true,
      filterSelector: 'Patient.patient_first_name'
    })
    .addColumn({
      name: 'Last Name',
      selector: (row) => row.Patient.patient_last_name,
      type: 'text',
      sortable: true,
      filterable: true,
      filterSelector: 'Patient.patient_last_name'
    })
    .addColumn({
      name: 'Admission Date',
      selector: (row) => row.admission_date,
      type: 'datetime',
      sortable: true
    })
    .addColumn({
      name: 'Discharge Date',
      selector: (row) => row.discharge_date,
      type: 'datetime',
      sortable: true
    })
    .addColumn({
      name: 'Branch',
      selector: (row) => row.branch,
      sortable: true,
      filterable: true,
      filterSelector: 'branch'
    })
    .addColumn({
      name: 'Ward',
      selector: (row) => row.ward,
      sortable: true,
      filterable: true,
      filterSelector: 'ward'
    });

  return <DataList apiConfig={apiConfig} columns={apiConfig.columns} />;
};

export default PatientAdmissionList;
