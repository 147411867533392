import axios from 'axios';
import { getAuth } from 'firebase/auth';


export function getPrevMonthStartDate() {
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return formatDate(firstDayOfMonth);
  }

export function getPrevMonthEndDate() {
    const date = new Date();
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 0);
    return formatDate(lastDayOfMonth);
  }

/* take a date in the format of 2024-01-31 and returns 31 Jan 2024*/
export function formatDateToZoho(dateString) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const [year, month, day] = dateString.split('-');
    const monthIndex = parseInt(month, 10) - 1; // Month index starts from 0
    
    return `${parseInt(day, 10)} ${months[monthIndex]} ${year}`;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

 /**
 * Returns the current date formatted as 'YYYY-MM-DD'.
 * Optionally allows adding or subtracting days.
 * @param {number} daysToAdd - The number of days to add (can be negative).
 * @returns {string} - Formatted date as 'YYYY-MM-DD'.
 */
export const getFormattedDate = (daysToAdd = 0) => {
  const today = new Date();
  today.setDate(today.getDate() + daysToAdd); // Adjust the date by daysToAdd
  return today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
};

export const getFormattedDateTime = (inputDate = new Date(), daysToAdd = 0) => {
  const date = new Date(inputDate); // Use the passed-in date or the current date

  date.setDate(date.getDate() + daysToAdd); // Adjust the date by daysToAdd

  const day = String(date.getDate()).padStart(2, '0');
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[date.getMonth()]; // Get abbreviated month name
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`; // Format as 'DD MMM YYYY HH:MM:SS'
};


export const getFormattedISODateTime = (inputDate = new Date()) => {
  console.log('Received Date; ',inputDate);
  const now = new Date(inputDate);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// In your component
<input type="datetime-local" defaultValue={getFormattedDateTime()} />

/**
 * Sets up Axios interceptors for handling authorization and error responses globally.
 *
 * Usage:
 * Call this function during application initialization (e.g., in App.js or AuthProvider)
 * to ensure Axios requests are consistently configured with the required headers
 * and error handling logic.
 *
 * Features:
 * - Automatically attaches the Authorization header with a Bearer token from the current user's session.
 * - Refreshes expired tokens by using Firebase's `getIdToken()` method.
 * - Handles 401 (Unauthorized) and 403 (Forbidden) errors, allowing for redirection or other custom behavior.
 *
 * Dependencies:
 * - Axios: Ensure Axios is imported and globally used for API requests.
 * - Firebase Auth: Uses Firebase authentication to retrieve ID tokens.
 *
 * Example:
 * ```
 * import setupAxiosInterceptors from './utils/Utils';
 * setupAxiosInterceptors();
 * ```
 *
 * Notes:
 * - This function modifies Axios' global behavior, so it should be used with caution in multi-instance scenarios.
 * - Ensure Firebase and Axios are properly configured in your project.
 *
 * @returns {void} This function does not return any value.
 */
export const setupAxiosInterceptors = () => {
  const auth = getAuth();

  axios.interceptors.request.use(
    async (config) => {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken(true); // Get a fresh token
        config.headers.Authorization = `Bearer ${idToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        console.warn('Unauthorized request. Redirect to login if necessary.');
      }
      return Promise.reject(error);
    }
  );
};



/**
 * Recursively searches for a permission key in a permissions object.
 * @param {Object} permissionsObject - The permissions object.
 * @param {string} key - The permission key to look for.
 * @param {string} type - The permission type (e.g., "View", "Edit").
 * @returns {boolean} - True if the permission is granted.
 */
export const findPermission = (permissionsObject, key, type) => {
  // Direct match
  console.log('Checking Permissions from Util for key:',key,' and type: ',type);
  if (permissionsObject?.[key]?.[type] === 'true') {
    return true;
  }
  // Check children if they exist
  for (const prop in permissionsObject) {
    if (permissionsObject[prop].children) {
      if (findPermission(permissionsObject[prop].children, key, type)) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Checks if the given permissions object grants a specific permission.
 * @param {Object} permissions - The entire permissions object.
 * @param {string} key - The permission key to check.
 * @param {string} type - The permission type ("View", "Add", "Edit", "Delete").
 * @returns {boolean} - True if permission is granted.
 */
export const checkPermission = (permissions, key, type) => {
  return findPermission(permissions, key, type);
};
