import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../hooks/useAuth'; // Assuming you have a useAuth hook
import { v4 as uuidv4 } from 'uuid';
import { getFormattedISODateTime } from '../assets/Utils';
import { useNavigate } from 'react-router-dom';
import AdvancedArrayInput from './AdvancedArrayInput';



const FormModal = ({ initialData, onClose, apiConfig }) => {
  const { user,token } = useAuth();
  const userRole = user?.role;
  const navigate = useNavigate();
  const formatDateForInput = (date) => {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      return parsedDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
    }
    return date;
  };

  const initializeFormData = () => {
    const data = initialData || {};

    //console.log("Data before initialization: ",data);
    apiConfig.fields.forEach((field) => {
    
    let value = data[field.name];
      

      if ((value === undefined || value === null) && field.defaultValue !== undefined) {
        value = field.defaultValue;
       // console.log("Just set a default value for ",field.name," with the value: ",field.defaultValue);
      }

      // Format date fields for input if they already have a value
      if (field.type === 'date' && value) {
        value = formatDateForInput(value);
      }

     // Parse JSON fields if they are stored as strings
     if (field.type === 'advanced-array') {
      if (typeof value === 'string') {
        try {
          // First parse to remove the extra layer of quotes if necessary
          const parsedValue = JSON.parse(value);
          console.log("Parsed Value:",parsedValue, " and type:",typeof parsedValue);
          // Check if parsedValue is still a string and needs another parse
          if (typeof parsedValue === 'string') {
            value = JSON.parse(parsedValue);
          } else {
            value = parsedValue;
          }
        } catch (e) {
          console.warn(`Failed to parse field ${field.name}`, e);
        }
      }

      // Ensure the value is an array for advanced-array fields
      if (!Array.isArray(value)) {
        value = [];
      }
    }

     if (typeof data[field.name] === 'object' && field.type !== 'advanced-array') {
     // console.log('Found an object: ',field.name," with the value: ",value);
        value = JSON.stringify(value);
      }

      if (field.onInitialize) {
        value = field.onInitialize(value, data);
      }

      if (value === "null") value = null;
      
      data[field.name] = value;
    })
    //console.log('Initial Data:', data);
    return data;
  };
  
  const [formData, setFormData] = useState(initializeFormData());
  const [lastSavedData, setLastSavedData] = useState(initialData);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState({});

  // Determine the initial active tab
  const accessibleTabs = (apiConfig.tabs || []).filter((tabConfig) => {
    if (tabConfig.roles === 'all') {
      return true; // Bypass security check for "all"
    }
    const allowedRoles = tabConfig.roles?.split(',').map((role) => role.trim()) || [];
    return allowedRoles.includes(userRole);
  });

  const defaultTab =
    accessibleTabs.find((tabConfig) => tabConfig.default) || accessibleTabs[0] || { name: 'Main' };

  const [activeTab, setActiveTab] = useState(defaultTab.name);
  const [isSubmitting, setIsSubmitting] = useState(false); 

  useEffect(() => {
    console.log("Form Data:",initialData);
    if (apiConfig.dropdownFields || apiConfig.staticDropdownFields) {
      fetchDropdownOptions();
    } else {
        setLoadingOptions(false);
    }
  }, [apiConfig.dropdownFields, apiConfig.staticDropdownFields]);

  useEffect(() => {
    setFormData(initializeFormData());
  }, [initialData]);

  const fetchDropdownOptions = async () => {
    setLoadingOptions(true);
    const options = {};

    if (apiConfig.staticDropdownFields) {
      for (const field of apiConfig.staticDropdownFields) {
        options[field.name] = field.options.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }
    }

    if (apiConfig.dropdownFields) {
      for (const field of apiConfig.dropdownFields) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${field.endpoint}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });
          options[field.name] = response.data.map((item) => ({
            value: item[field.valueField],
            label: item[field.labelField],
          }));
        } catch (error) {
          console.error(`Error fetching options for ${field.name}:`, error);
        }
      }
    }

    setDropdownOptions(options);
    setLoadingOptions(false);
  };

  const getIdentifierValue = () => {
    const identifierField = apiConfig.fields.find((field) => field.identifier);
    return identifierField ? formData[identifierField.name] : null;
  };

  const handleInputChange = (field,e) => {
    const { name, value, type, checked, files } = e.target;
    let fieldValue;
  
    if (type === 'checkbox') {
      fieldValue = checked;
    } else if (type === 'file') {
      fieldValue = files[0] || null; // Handle file input (single file for now)
    } else {
      fieldValue = value ?? '';
    }
  

  const updatedFormData = { ...formData, [name]: fieldValue };

  setFormData(updatedFormData);

    if (field.onChange) {
        field.onChange(fieldValue, updatedFormData, setFormData,name);
      }
  };

  const handleAdvancedArrayInputChange = (name, value) => {
    console.log("Advanced Array Input Change:", name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = async (file, fieldName) => {
    if (!file) return;
    const recordId = getIdentifierValue();
    const bucketIdValue = formData[apiConfig.gcBucketIdentifier];
    const fileMainId = bucketIdValue?bucketIdValue:recordId;
    const fileName = bucketIdValue?`${recordId}/${cleanFileName(file.name)}`:cleanFileName(file.name);
    if(fileMainId!=null) {
        const filePath = `${apiConfig.gcBucketName}/${fileMainId}/${fieldName}/${fileName}`; // Construct the full path for the file
        const payloadData = new FormData();
        payloadData.append('file', file);
        payloadData.append('filePath', filePath);
        payloadData.append('id',recordId);
        payloadData.append('columnName',fieldName);
    
        setFormData({ ...formData, [fieldName]: null }); // Reset field while uploading
      
        try {

        const response = await axios.post(
            `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/documents/lab-results/upload-pdf`,
            payloadData,
            {
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentage);
                  },
            },
        );
        setFormData({ ...formData, [fieldName]: response.data.path }); // Save file path
        
       // alert('File uploaded successfully!');
        } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file.');
        } finally {
            setUploadProgress(0); // Reset progress after upload is done
          }
    } else {
        setFormData({ ...formData, [fieldName]: null }); 
        alert('Record must be saved before uploading any files!');
    }
  };

  const handleDropdownChange = (selectedOption, action) => {
    setFormData({ ...formData, [action.name]: selectedOption?.value || '' });
  };

  const isFormValid = () => {
    return apiConfig.fields
      .filter((field) => field.required)
      .every((field) =>  !!formData[field.name]);
  };

  const simulateDelay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const parseFormDataForSubmission = (data) => {
    const parsedData = { ...data };
    apiConfig.fields.forEach((field) => {
      if (field.type === 'file') {
        return; // Skip file fields
      }
      try {
        // Parse back JSON strings if applicable
        if (typeof parsedData[field.name] === 'string' && field.allowJson) {
          parsedData[field.name] = JSON.parse(parsedData[field.name]);
        }
      } catch (e) {
        console.warn(`Failed to parse field ${field.name}`, e);
      }
    });
    return parsedData;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const identifierValue = getIdentifierValue();

    if(apiConfig.additionalValidation) {
        const validationResult = apiConfig.additionalValidation(formData);
        if(!validationResult.isValid) {
            setStatusMessage({ type: 'error', text: validationResult.message });
            setIsSubmitting(false);
            return;
        }
    }

    // Get the fields that have changed
    const payload = getChangedFields(formData, lastSavedData);
   // console.log("Payload:",payload);
    const finalPayload = includeUpdatedBy(payload, user.portal_id); // Add updatedBy

    try {
        if (identifierValue) {
            // PUT for updates
            await axios.put(
                `${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.updateEndpoint}/${identifierValue}`,
                finalPayload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setLastSavedData(formData);
            setStatusMessage({ type: 'success', text: 'Form submitted successfully!' });
        } else {
            // POST for new records
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.createEndpoint}`,
                finalPayload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const identifierField = apiConfig.fields.find((fld) => fld.identifier);
            console.log("Identifier name:",identifierField);
            console.log("return ID: ", response.data[identifierField.name]);
            const updatedFormData = {...formData, [identifierField.name]:response.data[identifierField.name]};
            setFormData(updatedFormData);

            setLastSavedData(updatedFormData);
           setStatusMessage({ type: 'success', text: 'Form submitted successfully!' });
        }
    } catch (error) {
        console.error('Error saving record:', error);
       // alert('Failed to save record.');
       setStatusMessage({ type: 'error', text: 'Failed to save data. If problem persists, please contact your administrator.' });
    } finally {
        
        setIsSubmitting(false); // Re-enable the button
    }
};
const getChangedFields = (formData, initialData) => {
    const changes = {};
    Object.keys(formData).forEach((key) => {
        const formValue = formData[key];
        const initialValue = initialData?.[key];

        // Check for changes: compare values, handling JSON for nested objects
        if (JSON.stringify(formValue) !== JSON.stringify(initialValue)) {
            changes[key] = formValue;
        }
    });
   // console.log("Changes: ",changes);
    return changes;
};

const hasChanges = () => Object.keys(getChangedFields(formData, lastSavedData)).length > 0;

const handleNavigation = (event, path, linkIdField) => {
    if (hasChanges()) {
      event.preventDefault(); // Prevent the default navigation behavior
      const confirmLeave = window.confirm("You have unsaved changes. Do you really want to leave?");
      if (!confirmLeave) return;
    }
  
    navigate(path, { state: { id: formData[linkIdField] } });
  };

  const handleRemoveFile = async (fieldName) => {
    const filePath = formData[fieldName];
    if (!filePath) return;
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/documents/lab-results/delete-pdf`,
        { gcPath: filePath, columnName: fieldName, id: getIdentifierValue() },
      );
      setStatusMessage({ type: 'success', text: 'File removed successfully!' });
      setFormData({ ...formData, [fieldName]: null }); // Clear the file field
    } catch (error) {
      console.error('Error removing file:', error);
      setStatusMessage({ type: 'error', text: 'Failed to remove file.'});

    }
  };

  const renderFileInput = (field) => (
    <div key={field.name} className="form-group-horizontal">
    {formData[field.name]?.split('/').pop().length > 5 ? (
      <div className="file-preview">
        <button type="button" onClick={() => handleDownload(`${formData[field.name]}`)}>
            {formData[field.name]?.split('/').pop().length > 30 
            ? `${formData[field.name].split('/').pop().slice(0, 30)}...` 
            : formData[field.name].split('/').pop()}
        </button>
        <button
          type="button"
          onClick={() => handleRemoveFile(field.name)}
          className="remove-file-button"
        >
          Remove File
        </button>
      </div>
    ) : (
      <input
        type="file"
        name={field.name}
        accept={field.accept || '*'}
        onChange={(e) => handleFileUpload(e.target.files[0], field.name)}
        disabled={!getIdentifierValue()}
      />
    )}
  </div>
  );
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '40px',
      minHeight: '40px',
      backgroundColor: state.isDisabled ? '#e9ecef' : provided.backgroundColor,
      padding: '0px',
      marginBottom: '0px',
      marginRight: '0px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '40px',
      padding: '0 8px',
    }),
    container: (provided) => ({
        ...provided,
        padding: '0px 0px',
        margin: '0px 0px',
    })
  };

  const handleDownload = async (filePath) => {
    setUploadProgress(0); 
    try {
      // Make a GET request to the download endpoint with Axios
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/documents/download`,
        {
          params: { filePath },
          responseType: 'blob', // ensures the response is treated as a file (binary data)
          onDownloadProgress: (progressEvent) => {
            let percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(`Download Progress: ${percentage}%`);
            setUploadProgress(percentage); // Update progress state
          },
        }
      );
  
      // Extract the file name from the filePath or Content-Disposition header
      const fileName = response.headers['content-disposition']
        ? response.headers['content-disposition']
            .split('filename=')[1]
            ?.replace(/['"]/g, '') // Clean up quotes if present
        : filePath.split('/').pop();
  
      // Create a Blob URL for the file
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const blobUrl = window.URL.createObjectURL(blob);
  
      // Create an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName || 'downloaded-file'; // Fallback if fileName is undefined
      document.body.appendChild(link); // Append link to the DOM
      link.click(); // Programmatically trigger the click event
      document.body.removeChild(link); // Clean up the DOM
  
      // Revoke the Blob URL after the download starts
      window.URL.revokeObjectURL(blobUrl);

      setUploadProgress(100);
      setTimeout(() => {
        setUploadProgress(0);
      }, 500); 

    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download file. Please try again.');
      setUploadProgress(0); // Reset progress in case of error
    }
  };
  

  const ensureOptionIncluded = (field, value) => {
    const options = dropdownOptions[field.name] || [];
    const exists = options.some((option) => option.value === value);
    if (!exists && value) {
      const newOption = { value, label: value };
      options.push(newOption);
      setDropdownOptions({ ...dropdownOptions, [field.name]: options });
    }
  };

  const groupedFields = apiConfig.fields.reduce((groups, field) => {
    const tab = field.tab || 'Main';
    if (!groups[tab]) groups[tab] = [];
    groups[tab].push(field);
    return groups;
  }, {});

  const includeUpdatedBy = (data, userId) => ({
    ...data,
    updatedBy: userId,
});

const cleanFileName = (fileName) => {
    return fileName
      .trim() // Remove leading/trailing spaces
      .replace(/\s+/g, '_') // Replace spaces with underscores
      .replace(/[/\\?%*:|"<>#]/g, '') // Remove disallowed characters
      .substring(0, 100); // Limit to 100 characters (optional)
  };
const handleClose = (e) => {
    if (hasChanges()) {
        e.preventDefault();
        const confirmLeave = window.confirm("You have unsaved changes. Do you really want to leave?");
        if (!confirmLeave) return;
      }
      onClose();
}

const getFormTitle = () => {
  if(getIdentifierValue()) {//this is an edit operation
    if(apiConfig.formTitle != '') {//custom title
       return `Editing ${apiConfig.formTitle}`;
    } else {
      return `Editing Record ID:  ${getIdentifierValue()}`;
    }
  } else {
    return `Add ${apiConfig.title}`
  }
}
  return (
    <div className="modal">
      <div className="modal-content">
        <h3>
          {getFormTitle()}
        </h3>

        <div className="tabs">
          {accessibleTabs.map((tabConfig) => (
            <button
              key={tabConfig.name}
              className={`tab-button ${activeTab === tabConfig.name ? 'active' : ''}`}
              onClick={() => setActiveTab(tabConfig.name)}
            >
              {tabConfig.name}
            </button>
          ))}
        </div>

        <form onSubmit={handleSubmit}>
          {groupedFields[activeTab]?.map((field) => (
            <div className="form-group-horizontal" key={field.name}>
              {field.visible===false ? (null):(
              <label
                style={{ color: field.required ? 'red' : 'inherit' }}
              >
                {field.label}
                {field.required && '*'}
              </label>
              )}
            {field.type === 'dropdown' ? (
              loadingOptions ? (
                <p>Loading options...</p>
              ) : (
                (() => {
                  // Get the options from your source.
                  let options = dropdownOptions[field.name] || [];
                  // If a defaultValue is specified, prepend it as an option.
                  console.log("Default Value Check:",field.defaultValue);
                  if (field.defaultValue) {
                    console.log("Default Value:",field.defaultValue);
                    options = [{ value: field.defaultValue, label: field.defaultValue }, ...options];
                  }
                  const currentValue = formData[field.name] ?? field.defaultValue;
                  return (
                    <Select
                      name={field.name}
                      options={options}
                      value={options.find(option => option.value === currentValue) || null}
                      onChange={handleDropdownChange}
                      isClearable
                      placeholder={`Select ${field.label}`}
                      styles={customStyles}
                      isDisabled={field.disabled}
                      hidden={field.visible === false}
                    />
                  );
                })()
              )
            ) : field.type === 'checkbox' ? (
                <input
                  type="checkbox"
                  name={field.name}
                  checked={!!formData[field.name]}
                  onChange={(e) => handleInputChange(field, e)}
                  disabled={field.disabled}
                  hidden={field.visible===false}
                />
              ) : field.type === 'file' ? (
                    renderFileInput(field)
              ) : field.type === 'textarea' ? (
            <textarea
            name={field.name}
            value={
                formData[field.name] === null || formData[field.name] === undefined
                ? '' // Set empty string for null or undefined
                : formData[field.name]
            }
            onChange={(e) => handleInputChange(field, e)}
            disabled={field.disabled}
            rows={field.maxRows || 3}
            hidden={field.visible===false}
            />
              ): field.type === 'datetime-local' ?
              (
                <input
                  type={field.type}
                  name={field.name}
                  value={ getFormattedISODateTime(formData[field.name] ?? '')}
                  onChange={(e) => handleInputChange(field, e)}
                  disabled={field.disabled}
                  hidden={field.visible===false}
                />
              ): field.type === 'link' ?
              (
                <span
                className="custom-link"
                onClick={(event) => handleNavigation(event, field.path, field.linkIdField)}
              >
                {field.label}
              </span>
                ): field.type === 'advanced-array' ? (
                  <AdvancedArrayInput
                    key={field.name}
                    endpoint={field.endpoint}
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleAdvancedArrayInputChange}
                    additionalFields={field.additionalFields || []}
                  />
                ) :
              (
                <input
                type={field.type}
                name={field.name}
                value={formData[field.name] ?? ''}
                onChange={(e) => handleInputChange(field, e)}
                disabled={field.disabled}
                hidden={field.visible===false}
              />               
              )
            }
            </div>
          ))}
          <div className="form-actions">
            <button type="submit" disabled={!isFormValid() || loadingOptions || isSubmitting}>
              Save
            </button>
            <button type="button" onClick={(e) => handleClose(e)}>
              {hasChanges()?(
                'Cancel'
              ): (
                'Close'
              )
            }
              
            </button>
          </div>
          {uploadProgress > 0 && (
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${uploadProgress}%` }}
          >Processing ...
          </div>
        </div>
      )}
      {statusMessage && statusMessage.text && (
        <div className={`status-message ${statusMessage.type === 'error' ? 'error' : 'success'}`}>
        {   statusMessage.text}
            </div>
        )}
        </form>
      </div>
    </div>
  );
};

export default FormModal;
