import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/LabTestRequestDetailForm.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LabTestRequestDetailForm = () => {
  const [groupedTests, setGroupedTests] = useState({});
  const [results, setResults] = useState({});
  const [requestHeader, setRequestHeader] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // CBC file upload states
  const [selectedFile, setSelectedFile] = useState(null);
  const [cbcResults, setCbcResults] = useState(null);
  const [isProcessingCBC, setIsProcessingCBC] = useState(false);

  // Toggle for manual CBC entry
  const [isManualEntry, setIsManualEntry] = useState(false);

  // This array holds all CBC fields for manual entry
  const defaultCbcManualData = [
    { testName: 'WBC', testValue: '', unit: '10^9/L', range: '5.00-11.60' },
    { testName: 'LYM#', testValue: '', unit: '10^9/L', range: '1.30-4.00' },
    { testName: 'MID#', testValue: '', unit: '10^9/L', range: '0.30-1.00' },
    { testName: 'GRA#', testValue: '', unit: '10^9/L', range: '2.40-7.60' },
    { testName: 'LYM%', testValue: '', unit: '%',      range: '19.1-48.5' },
    { testName: 'MID%', testValue: '', unit: '%',      range: '4.5-12.1' },
    { testName: 'GRA%', testValue: '', unit: '%',      range: '43.60-73.4' },
    { testName: 'RBC',  testValue: '', unit: '10^12/L', range: '3.79-5.78' },
    { testName: 'HGB',  testValue: '', unit: 'g/dL',   range: '11.50-17.3' },
    { testName: 'HCT',  testValue: '', unit: '%',      range: '39.90-53.90' },
    { testName: 'MCV',  testValue: '', unit: 'fL',     range: '84-98' },
    { testName: 'MCH',  testValue: '', unit: 'pg',     range: '27-33' },
    { testName: 'MCHC', testValue: '', unit: 'g/dL',   range: '32-36' },
    { testName: 'RDW-CV', testValue: '', unit: '%',    range: '11.5-14.5' },
    { testName: 'PLT',    testValue: '', unit: '10^9/L', range: '150-342' },
    { testName: 'PCT',    testValue: '', unit: '%',      range: '0.16-0.36' },
    { testName: 'MPV',    testValue: '', unit: 'fL',     range: '8.3-12.1' },
    { testName: 'PDW',    testValue: '', unit: 'fL',     range: '37.8-43.6' },
    { testName: 'P-LCC',  testValue: '', unit: '10^9/L', range: '55-139' },
    { testName: 'P-LCR',  testValue: '', unit: '%',      range: '25.30-53.80' },
  ];
  const [manualCbcData, setManualCbcData] = useState(defaultCbcManualData);

  // New state for Findings/Recommendations.
  const [findings, setFindings] = useState("");

  // New state for result notes:
  const [visibleNotes, setVisibleNotes] = useState({}); // { [testId]: boolean }
  const [notes, setNotes] = useState({}); // { [testId]: string }

  const navigate = useNavigate();
  const { request } = useLocation().state || {};
  const id = request ? request.id : undefined;

  // Set header if request is provided.
  useEffect(() => {
    if (request) {
      setRequestHeader(request);
    }
  }, [request]);

  // Fetch lab tests for the given request id.
  const fetchLabTests = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/lab-request-details/${id}`
      );
      const details = response.data.details;

      // Group tests by category then by test type.
      const grouped = details.reduce((acc, test) => {
        const category = test.MedicalLabTest.category;
        const type = test.MedicalLabTest.type;
        if (!acc[category]) acc[category] = {};
        if (!acc[category][type]) acc[category][type] = [];
        acc[category][type].push(test);
        return acc;
      }, {});
      setGroupedTests(grouped);

      if (!requestHeader && response.data.request) {
        setRequestHeader(response.data.request);
      }
      //set the findings
      setFindings(response.data.request.findings);
      // Initialize results state for manual entry.
      const initialResults = details.reduce((acc, test) => {
        acc[test.id] = test.result || '';
        return acc;
      }, {});
      setResults(initialResults);

      // Initialize notes state from result_note field.
      const initialNotes = details.reduce((acc, test) => {
        acc[test.id] = test.result_note || '';
        return acc;
      }, {});
      setNotes(initialNotes);

      // Load existing CBC results if available.
      for (const category in grouped) {
        if (grouped[category]['CBC'] && grouped[category]['CBC'].length > 0) {
          const cbcTest = grouped[category]['CBC'][0];
          if (cbcTest.result) {
            try {
              const parsed = JSON.parse(cbcTest.result);
              setCbcResults(parsed);
            } catch (error) {
              console.error('Error parsing CBC result JSON:', error);
            }
          }
          break;
        }
      }
    } catch (error) {
      console.error('Error fetching lab test details:', error);
      toast.error('Error fetching lab test details.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchLabTests();
    }
  }, [id]);

  // Handle text input changes for non-CBC tests.
  const handleResultChange = (testId, value) => {
    setResults((prevResults) => ({
      ...prevResults,
      [testId]: value,
    }));
  };

  // Toggle the visibility of the note field for a test.
  const toggleNote = (testId) => {
    setVisibleNotes((prev) => ({
      ...prev,
      [testId]: !prev[testId],
    }));
  };

  // Update note text for a test.
  const updateNote = (testId, value) => {
    setNotes((prev) => ({
      ...prev,
      [testId]: value,
    }));
  };

  // Save the results (for all tests), including result_note if available, and findings.
  const handleSave = async (markComplete = false) => {
    setIsSubmitting(true);
    try {
      const payload = {
        results: Object.entries(results).map(([testId, result]) => ({
          testId: parseInt(testId, 10),
          result,
          result_note: notes[testId] || '',
        })),
        findings,
        markComplete,
      };
      console.log("results payload:", payload);
      await axios.put(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/lab-test-request-details/${id}/results`,
        payload
      );

      toast.success(
        `Results ${markComplete ? 'saved and request marked as complete' : 'saved successfully'}.`
      );
      setTimeout(() => navigate(-1), 1500);
    } catch (error) {
      console.error('Error saving results:', error);
      toast.error('Failed to save results. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // --- CBC File Processing Functions ---

  const processCBCResults = (data) => {
    const rows = [];
    const lowerKeyMapping = {};
    Object.keys(data).forEach((k) => {
      lowerKeyMapping[k.toLowerCase()] = k;
    });
    Object.keys(data).forEach((key) => {
      const lowerKey = key.toLowerCase();
      if (
        !lowerKey.endsWith('interval') &&
        !lowerKey.endsWith('intervals') &&
        !lowerKey.endsWith('interval-l') &&
        !lowerKey.endsWith('intervals-l')
      ) {
        let testName = key;
        if (lowerKey === 'basophi-percent') {
          testName = 'Basophil %';
        } else if (lowerKey === 'basophil') {
          testName = 'Basophil #';
        } else if (lowerKey === 'eosinophil') {
          testName = 'Eosinophil #';
        } else if (lowerKey === 'eosinophil-percent') {
          testName = 'Eosinophil %';
        } else if (lowerKey === 'neutrophil-percent') {
          testName = 'Neutrophil %';
        } else if (lowerKey === 'neutrophil') {
          testName = 'Neutrophil #';
        } else if (lowerKey === 'lymphocyte') {
          testName = 'Lymphocyte #';
        } else if (lowerKey === 'lymphocytes') {
          testName = 'Lymphocytes %';
        } else if (lowerKey === 'monocyte') {
          testName = 'Monocyte #';
        } else if (lowerKey === 'monocytes') {
          testName = 'Monocytes %';
        }

        let correctedKey = key;
        if (lowerKey === 'basophil') {
          correctedKey = 'Basaphil';
        }
        const testValue = data[key];
        const upper1 = (correctedKey + '-interval').toLowerCase();
        const upper2 = (correctedKey + '-intervals').toLowerCase();
        const lower1 = (correctedKey + '-interval-l').toLowerCase();
        const lower2 = (correctedKey + '-intervals-l').toLowerCase();

        const originalUpperKey = lowerKeyMapping[upper1] || lowerKeyMapping[upper2] || '';
        const originalLowerKey = lowerKeyMapping[lower1] || lowerKeyMapping[lower2] || '';
        const rangeValue = data[originalLowerKey] + ' - ' + data[originalUpperKey];
        rows.push({ testName, testValue, range: rangeValue });
      }
    });
    return rows;
  };

  const saveCBCResult = async (cbcTestId, cbcData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/lab-test-request-details/${id}/results`,
        {
          results: [{ testId: cbcTestId, result: JSON.stringify(cbcData) }],
        }
      );
      toast.success('CBC results saved to database.');
    } catch (error) {
      console.error('Error saving CBC result:', error);
      toast.error('Error saving CBC result.');
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error('Please select a file.');
      return;
    }
    setIsProcessingCBC(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/documents/lab-results/process-cbc-pdf?DocumentType=CBC`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      console.log('Raw CBC response:', response.data);
      const processedResults = processCBCResults(response.data);
      console.log('Processed CBC results:', processedResults);
      setCbcResults(processedResults);
      toast.success('CBC file processed successfully.');
      setIsProcessingCBC(false);

      let cbcTestId = null;
      for (const category in groupedTests) {
        if (groupedTests[category]['CBC'] && groupedTests[category]['CBC'].length > 0) {
          cbcTestId = groupedTests[category]['CBC'][0].id;
          break;
        }
      }
      if (cbcTestId) {
        setResults((prevResults) => ({
          ...prevResults,
          [cbcTestId]: JSON.stringify(processedResults),
        }));
        await saveCBCResult(cbcTestId, processedResults);
      } else {
        toast.warn('No CBC test found in the request.');
      }
    } catch (error) {
      console.error('Error processing CBC file:', error);
      toast.error('Error processing CBC file.');
      setIsProcessingCBC(false);
    }
  };

  // Handle manual input change for CBC manual entry.
  const handleManualInputChange = (index, field, value) => {
    setManualCbcData((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  // Save manual CBC input.
  const handleManualSave = async () => {
    let cbcTestId = null;
    for (const category in groupedTests) {
      if (groupedTests[category]['CBC'] && groupedTests[category]['CBC'].length > 0) {
        cbcTestId = groupedTests[category]['CBC'][0].id;
        break;
      }
    }
    if (!cbcTestId) {
      toast.warn('No CBC test found in the request.');
      return;
    }
    try {
      setResults((prevResults) => ({
        ...prevResults,
        [cbcTestId]: JSON.stringify(manualCbcData),
      }));
      await saveCBCResult(cbcTestId, manualCbcData);
      setIsManualEntry(false);
      toast.success("Manual CBC results saved successfully.");
    } catch (error) {
      console.error('Error saving manual CBC results:', error);
      toast.error('Error saving manual CBC results.');
    }
  };

  if (isLoading) {
    return (
      <div className="lab-test-request-detail-form">
        <ToastContainer />
        <div className="loading-container">
          <div className="spinner"></div>
          <p className="loading-text">Loading lab test details...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="lab-test-request-detail-form">
      <ToastContainer />
      <h2>Lab Test Request Details</h2>
      {requestHeader && (
        <div className="request-header">
          <p>
            <strong>Request ID:</strong> {requestHeader.id}
          </p>
          <p>
            <strong>Patient Name:</strong>{' '}
            {`${requestHeader.Patient.patient_first_name} ${requestHeader.Patient.patient_last_name}`}
          </p>
          <p>
            <strong>Status:</strong> {requestHeader.status}
          </p>
        </div>
      )}

      <form className="test-result-form">
        {Object.entries(groupedTests).map(([category, types]) => (
          <div key={category} className="category-group">
            <h3>{category}</h3>
            <div className="type-group-container">
              {Object.entries(types).map(([type, tests]) => (
                <div key={type} className="type-group">
                  <h4>{type}</h4>
                  {type.toLowerCase() === 'cbc' ? (
                    <div className="cbc-upload-section">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleFileChange}
                      />
                      <button type="button" onClick={handleFileUpload}>
                        Process CBC File
                      </button>
                      <button
                        type="button"
                        onClick={() => setIsManualEntry(!isManualEntry)}
                      >
                        {isManualEntry ? "Cancel Manual Entry" : "Manual Entry"}
                      </button>
                      {isProcessingCBC && (
                        <div className="processing-indicator">
                          <div className="spinner"></div>
                          <p className="processing-text">Processing CBC file...</p>
                        </div>
                      )}
                      {!isManualEntry && cbcResults && (
                        <div className="cbc-results">
                          <h4>CBC Results:</h4>
                          <table className="results-table">
                            <thead>
                              <tr>
                                <th>Test Name</th>
                                <th>Test Value</th>
                                <th>Range</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cbcResults.map((row, idx) => (
                                <tr key={idx}>
                                  <td>{row.testName}</td>
                                  <td>{row.testValue}</td>
                                  <td>{row.range}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {isManualEntry && (
                        <div className="manual-cbc-entry">
                          <h4>Manual CBC Entry</h4>
                          <table className="results-table manual-cbc-table">
                            <thead>
                              <tr>
                                <th>Test Name</th>
                                <th>Test Value</th>
                                <th>Unit</th>
                                <th>Reference Range</th>
                              </tr>
                            </thead>
                            <tbody>
                              {manualCbcData.map((row, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <input
                                      type="text"
                                      value={row.testName}
                                      readOnly
                                      style={{ backgroundColor: '#eee' }}
                                      tabIndex="-1"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={row.testValue}
                                      onChange={(e) =>
                                        handleManualInputChange(idx, 'testValue', e.target.value)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={row.unit}
                                      readOnly
                                      style={{ backgroundColor: '#eee' }}
                                      tabIndex="-1"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={row.range}
                                      onChange={(e) =>
                                        handleManualInputChange(idx, 'range', e.target.value)
                                      }
                                      tabIndex="-1"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <button type="button" onClick={handleManualSave}>
                            Save Manual CBC Results
                          </button>
                          <button type="button" onClick={() => setManualCbcData(defaultCbcManualData)}>
                            Clear Manual Entries
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    tests.map((test) => (
                      <div key={test.id} className="test-row">
                        <span className="test-name">
                          {test.MedicalLabTest.test_name}
                        </span>
                        <input
                          type="text"
                          className="test-result-input"
                          placeholder="Enter result"
                          maxLength="50"
                          value={results[test.id]}
                          onChange={(e) => handleResultChange(test.id, e.target.value)}
                          disabled={isSubmitting}
                        />
                        <span
                          className="result-note-icon"
                          onClick={() => toggleNote(test.id)}
                          title="Add/View Note"
                        >
                          📝
                        </span>
                        {visibleNotes[test.id] && (
                          <textarea
                            className="result-note-text"
                            placeholder="Enter note..."
                            value={notes[test.id] !== undefined ? notes[test.id] : ''}
                            onChange={(e) => updateNote(test.id, e.target.value)}
                            rows="4"
                          />
                        )}
                      </div>
                    ))
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        {/* Findings/Recommendations Section */}
        <div className="findings-container">
          <label htmlFor="findingsInput"><strong>Findings/Recommendations:</strong></label>
          <textarea
            id="findingsInput"
            value={findings}
            onChange={(e) => setFindings(e.target.value)}
            placeholder="Enter findings and recommendations here..."
          />
        </div>
        <div className="form-actions">
          <button
            type="button"
            className="btn-save"
            onClick={() => handleSave(false)}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </button>
          <button
            type="button"
            className="btn-save-complete"
            onClick={() => handleSave(true)}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save and Mark Complete'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LabTestRequestDetailForm;
