import React, {useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../hooks/useAuth';
import '../styles/ViewModal.css';
import axios from 'axios';


const handleDownload = async (filePath) => {
    try {
      // Make a GET request to the download endpoint with Axios
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/documents/download`, {
        params: { filePath }, 
        responseType: 'blob', // ensures the response is treated as a file (binary data)
      });
  
      const fileName = filePath.split('/').pop();
  
      // Create a URL for the file and trigger a download
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const blobUrl = window.URL.createObjectURL(blob);

      // Open the Blob URL in a new tab
      window.open(blobUrl, '_blank');
  
      // Clean up the object URL
      window.URL.revokeObjectURL(blobUrl);

    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download file. Please try again.');
    }
  };

const ViewModal = ({ record, onClose, onEdit, apiConfig,editable }) => {
  const { user } = useAuth();
  const modalRef = useRef(null);

  if (!record) return null;

  const canAccessTab = (roles) => {
    if (!roles || roles.includes('all')) return true;
    return roles.includes(user.role);
  };
  const handlePrint = () => {
    window.print();
  };


  return (
    <div className="view-modal">
      <div className="view-modal-content">
      <div className="view-modal-actions">
          {editable && (
            <button
              className="view-modal-edit-button"
              onClick={() => {
                onClose();
                onEdit(record);
              }}
            >
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
          )}
          <button
            className="view-modal-print-button"
            onClick={handlePrint}
          >
            <FontAwesomeIcon icon={faPrint} /> Print
          </button>
          <button className="view-modal-close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} /> Close
          </button>
        </div>
        <h2>View {apiConfig.title}</h2>
        <div className="view-modal-details">
          {apiConfig.tabs.map((tab) => (
            canAccessTab(tab.roles) && (
              <div key={tab.name} className="view-modal-section">
                <h3>{tab.label || tab.name}</h3>
                {apiConfig.fields
                  .filter((field) => field.tab === tab.name && (field.viewable ?? true))
                  .map((field) => (
                    <>
                    { field.visible === false ? (null):(
                    <div key={field.name} className="view-modal-field">
                      <strong>{field.label || field.name}:</strong> 
                      { field.type === 'file' && record[field.name]?.split('/').pop() ? (
                        <button type="button" onClick={() => handleDownload(`${record[field.name]}`)}>
                             {record[field.name].split('/').pop().length > 30 
                             ? `${record[field.name].split('/').pop().slice(0, 30)}...` 
                             : record[field.name].split('/').pop()}
                        </button>
                      ): field.type === 'advanced-array' ? (
                        <table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              {field.additionalFields?.map((additionalField) => (
                                <th key={additionalField.name}>{additionalField.label}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {record[field.name]?.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                {field.additionalFields.map((additionalField) => (
                                  <td key={additionalField.name}>
                                    {item[additionalField.name]}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) :
                      
                      
                      (
                        <span>
                        {field.allowJson === true
                            ? JSON.stringify(record[field.name], '', 2) // Format the JSON for readability
                            : (record[field.name]===null?'':record[field.name])}
                        </span>
                      )}
                    </div>
                    )}
                    </>
                  ))}
              </div>
            )
          ))}
        </div>
 
      </div>
    </div>
   

  );
};

export default ViewModal;
