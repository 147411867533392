import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClipboardList, faCogs, faFileInvoiceDollar,faChartBar,faUserTie } from '@fortawesome/free-solid-svg-icons';
import '../styles/LandingPage.css'; // Add styles here
import { useAuth } from '../hooks/useAuth';


const MedicalReportsDashboard = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {rolePermissions } = useAuth();
  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/medicalreports/medical-contract/`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

function MyLink (props) { 
  const { path, iconname, name,id } = props;
  if(rolePermissions[props.path]?.View === 'true') {
   return (
  <Link to= {path}
        state= {{name: name,id: id }} // Pass customer_name in state
        className="link-card">
    <FontAwesomeIcon icon={iconname} size="3x" className="icon"/><p>{name}</p>
  </Link>);
  }
};

  return (
    <>
    {isLoading ? <p>Loading...</p> : (
        <div className="landing-page">
          {data.map((item, index) => (
            <div className="links-container" key={index}>
              <MyLink path="/medical-reports" iconname={faClipboardList} name={item.customer_name} id={item.id} />
            </div>
          ))}
        </div>
      )}
  </>
  );
};

export default MedicalReportsDashboard;
