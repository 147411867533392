import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../../styles/PatientBillingForm.css";
import { useAuth } from "../../hooks/useAuth";

const PatientBillingForm = ({ patient, bill, onClose }) => {
  // Assume the patient info is passed in via props.
  const [formData, setFormData] = useState(bill || {});
  const [billData, setBillData] = useState(null);
  const [selectedLabRequest, setSelectedLabRequest] = useState(null);
  const [selectedRadiologyRequest, setSelectedRadiologyRequest] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [salesNumber, setSalesNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [branch, setBranch] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [careoffName, setCareoffName] = useState("");

  const { hasPermission } = useAuth();
  // New state variables for detailed line items when an ID exists in formData.
  const [fetchedLabRequest, setFetchedLabRequest] = useState(null);
  const [fetchedRadiologyRequest, setFetchedRadiologyRequest] = useState(null);
  const [fetchedVisit, setFetchedVisit] = useState(null);
  const [visitEstimate, setVisitEstimate] = useState(0);
  const [labEstimate, setLabEstimate] = useState(0);

  const user = useAuth();

  const customerOptions = [
    { id: "4691741000000115035", name: "Admission Private Patient" },
    { id: "4691741000000115145", name: "Careoff" },
  ];

  useEffect(() => {
    if (!patient) {
      setLoading(false);
      return;
    }
    const fetchBillData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/billing/visit-bill/${patient.patient_id}`
        );
        setBillData(response.data);
      } catch (error) {
        console.error("Error fetching billing data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBillData();
  }, [patient]);

  // When formData contains an existing lab_request_id or visit_id, fetch detailed info.
  useEffect(() => {
    if (formData.lab_request_id) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/lab-requests/${formData.lab_request_id}`
        )
        .then((res) => {
          // Wrap in array if returned object.
          const data = res.data;
          setFetchedLabRequest(Array.isArray(data) ? data : [data]);
          console.log("Fetched Lab data:", data);
        })
        .catch((err) =>
          console.error("Error fetching lab request details:", err)
        );
    }
    if (formData.radiology_request_id) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/lab-requests/${formData.radiology_request_id}`
        )
        .then((res) => {
          // Wrap in array if returned object.
          const data = res.data;
          setFetchedRadiologyRequest(Array.isArray(data) ? data : [data]);
          console.log("Fetched Radiology data:", data);
        })
        .catch((err) =>
          console.error("Error fetching lab request details:", err)
        );
    }
    
    
    if (formData.visit_id) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/patients/visits/${formData.visit_id}`
        )
        .then((res) => {
          // Wrap in array if returned object.
          const data = res.data;
          setFetchedVisit(Array.isArray(data) ? data : [data]);
          console.log("Visit data:", data);
        })
        .catch((err) => console.error("Error fetching visit details:", err));
    }

    
  }, [formData.lab_request_id, formData.visit_id]);

  const handleRadioChangeLab = async (labId) => {
    const selectedLab = labRequestsToRender.find((lab) => lab.id === labId);
    const itemIds = selectedLab.LabTestRequestDetails.map(
      (detail) => detail.MedicalLabTest.item_id
    ).filter((id) => id);

    if (itemIds.length > 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/billing/estimate-cost`,
          { item_ids: itemIds }
        );
        console.log("response", response);
        setLabEstimate(response.data.estimated_cost);
      } catch (error) {
        console.error("Error fetching estimated cost:", error);
      }
    }
    setSelectedLabRequest(labId);
  };

  const handleRadioChangeRadiology = async (radiologyId) => {
    const selectedRadiology = radiologyRequestsToRender.find((lab) => lab.id === radiologyId);
    const itemIds = selectedRadiology.LabTestRequestDetails.map(
      (detail) => detail.MedicalLabTest.item_id
    ).filter((id) => id);

    if (itemIds.length > 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/billing/estimate-cost`,
          { item_ids: itemIds }
        );
        console.log("response", response);
        setLabEstimate(response.data.estimated_cost);
      } catch (error) {
        console.error("Error fetching estimated cost:", error);
      }
    }
    setSelectedRadiologyRequest(radiologyId);
  };
  const handleRadioChangeVisit = async (visitId) => {
    const selectedVisit = visitsToRender.find(
      (visit) => visit.visit_id === visitId
    );
    const itemIds = selectedVisit.prescription_items
      .map((item) => item.item_id)
      .filter((id) => id);
    if (itemIds.length > 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/billing/estimate-cost`,
          { item_ids: itemIds }
        );
        setVisitEstimate(response.data.estimated_cost);
      } catch (error) {
        console.error("Error fetching estimated cost:", error);
      }
    }
    setSelectedVisit(visitId);
  };

  const handleProcessInvoice = async () => {
    if (
      (!patient.company || patient.company === "") && patient.type === "Company") {
        alert("A company is required, please update the patient details");
        return;
    }
    if (!selectedVisit && !selectedLabRequest && !selectedRadiologyRequest) {
      alert("Please select a visit or lab/radiology request to process");
      return;
    }

    if (customerId === "4691741000000115145" && (!careoffName || careoffName === '')) {
      alert("A careoff name is required for Careoff Customers");
      return;
    }

    //  const branch = billData.visits?.find((visit) => visit.visit_id === selectedVisit)?.branch;

    if (!branch) {
      alert("Invalid or empty branch!");
      return;
    }

    const zoho_sales_id = bill ? bill.zoho_sales_id : null;

    setProcessing(true);
    try {
      const payload = {
        patient_id: patient.patient_id,
        zoho_sales_id: zoho_sales_id,
        lab_request_id: selectedLabRequest,
        radiology_request_id: selectedRadiologyRequest,
        visit_id: selectedVisit,
        bill_id: bill ? bill.id : null,
        branch: branch,
        updatedBy: user.portal_id,
        customer_id: customerId || null,
        careoff_name: customerId === "4691741000000115145" ? careoffName : null
      };
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/billing/process-patient-bill`,
        payload
      );
      console.log("response", response);
      setFormData(response.data);
      alert(
        "Invoice processed successfully: " + response.data.billing.sales_number
      );
      onClose();
    } catch (error) {
      console.error("Error processing invoice:", error.response.data);
      alert(`Failed to process invoice. ${error.response.data.zoho_response}`);
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return <div>Loading billing data...</div>;
  }

  // Decide which data to render for each section.
  const labRequestsToRender = fetchedLabRequest
    ? fetchedLabRequest
    : billData?.["lab-requests"];
  const radiologyRequestsToRender = fetchedRadiologyRequest
    ? fetchedRadiologyRequest
    : billData?.radiologyRequests;
  const visitsToRender = fetchedVisit ? fetchedVisit : billData?.visits;

  const renderBillingSection = (requestsToRender, type) => {
    return (
      <div className="bill-section">
      <h3>{type === "Lab" ? "Lab Requests" : "Radiology Requests"}</h3>
      <div className="scrollable-section">
        {requestsToRender && requestsToRender.length > 0 ? (
        <ul>
          {requestsToRender.map((request) => (
          <li key={request.id}>
            <label>
            {(type === "Lab"
              ? formData.lab_request_id === request.id
              : formData.radiology_request_id === request.id) ? (
              <span
              className="selected-icon"
              title="Already Selected"
              >
              ✔
              </span>
            ) : (
              <input
              type="radio"
              name={type === "Lab" ? "labRequest" : "radiologyRequest"}
              onChange={() =>
                type === "Lab"
                ? handleRadioChangeLab(request.id)
                : handleRadioChangeRadiology(request.id)
              }
              checked={
                type === "Lab"
                ? selectedLabRequest === request.id
                : selectedRadiologyRequest === request.id
              }
              />
            )}
            {type} Request #{request.id} - {request.status} - Requested
            By {request.requested_by}
            </label>
            {request.LabTestRequestDetails &&
            request.LabTestRequestDetails.length > 0 && (
              <ul className="child-details">
              {request.LabTestRequestDetails.map((detail) => (
                <li
                key={detail.id}
                className={
                  !detail.MedicalLabTest.item_id ? "highlight" : ""
                }
                >
                {detail.MedicalLabTest
                  ? detail.MedicalLabTest.test_name
                  : "Unknown Test"}
                {detail.MedicalLabTest.item_id ? (
                  ""
                ) : (
                  <span>
                  {" "}
                  * This item should be handled manually
                  </span>
                )}
                </li>
              ))}
              </ul>
            )}
          </li>
          ))}
        </ul>
        ) : (
        <p>There are no {type.toLowerCase()} requests to bill for this patient.</p>
        )}
      </div>
      </div>
    );
  };

  return (
    <div className="patient-bill-form modal-content">
      {/* Modal Header */}
      <div className="modal-header">
        <h2>{bill ? "Update Sales Record" : "Process Patient Bill"}</h2>
        <button className="modal-close" onClick={onClose}>
          ×
        </button>
      </div>
      <div className="existing-bill-info">
        <p>
          <strong>Patient Number:</strong>{" "}
          {bill ? bill.Patient.patient_number : patient.patient_number}
        </p>
        <p className="estimated-fees">
          <strong>Estimated Total Price:</strong>{" "}
          {Number(visitEstimate) + Number(labEstimate)}
        </p>

        <p>
          <strong>Sales Number:</strong> {bill?.sales_number}
        </p>
        <p>
          <strong>Visit ID:</strong> {bill?.visit_id}
        </p>
        <p>
          <strong>Lab Request ID:</strong> {bill?.lab_request_id}
        </p>
      </div>

      {/* Body */}
      {visitsToRender || labRequestsToRender ? (
        <>
          <div className="bill-section">
            <div className="form-group horizontal-group">
              <div>
                <label htmlFor="branch-select" className="requiredLabel">
                  <strong>Select Branch*</strong>
                </label>
                <select
                  id="branch-select"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                >
                  <option value="">Select Branch</option>
                  <option value="Pultney">Pultney</option>
                  <option value="Kissy">Kissy</option>
                </select>
              </div>
            </div>
          </div>
          {/* Customer Name Dropdown */}
          {patient.patient_type === "Private" && !bill?.sales_number && (
            <div className="bill-section">
              <div className="form-group horizontal-group">
                <div>
                  <label for="customer-select">
                    <strong>Bill To:</strong>
                  </label>
                  <select
                    id="customer-select"
                    value={customerId || ""}
                    onChange={(e) => setCustomerId(e.target.value)}
                  >
                    <option value="">Private</option>
                    {customerOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                  {customerId === "4691741000000115145" && (
                  <div>
                    <label for="careoff-name" className="requiredLabel">
                      <strong>Careoff Name*</strong>
                    </label>
                    <input
                      type="text"
                      id="careoff-name"
                      value={careoffName}
                      onChange={(e) => setCareoffName(e.target.value)}
                      placeholder="Enter Careoff Name"
                    />
                  </div>
                  )}
              </div>

            </div>
          )}
          {renderBillingSection(labRequestsToRender, "Lab")}
          {renderBillingSection(radiologyRequestsToRender, "Radiology")}

          <div className="bill-section">
            <h3>Visit Prescriptions and Consultations</h3>
            <div className="scrollable-section">
              {visitsToRender && visitsToRender.length > 0 ? (
                <ul>
                  {visitsToRender.map(
                    (visit) =>
                      visit.prescription_items &&
                      visit.prescription_items.length > 0 && (
                        <li key={visit.visit_id}>
                          <label>
                            {formData.visit_id === visit.visit_id ? (
                              <span
                                className="selected-icon"
                                title="Already Selected"
                              >
                                ✔
                              </span>
                            ) : (
                              <input
                                type="radio"
                                name="visit"
                                onChange={() =>
                                  handleRadioChangeVisit(visit.visit_id)
                                }
                                checked={selectedVisit === visit.visit_id}
                              />
                            )}
                            Visit #{visit.visit_id} on{" "}
                            {new Date(visit.visit_date).toLocaleDateString()}
                          </label>
                          {visit.prescription_items && (
                            <ul className="child-details">
                              {visit.prescription_items.map((item, idx) => (
                                <li
                                  key={idx}
                                  className={!item.item_id ? "highlight" : ""}
                                >
                                  {item.name || "Prescription Item"} (Qty{" "}
                                  {item.quantity}-{item.instructions})
                                  {!item.item_id && (
                                    <span>
                                      {" "}
                                      * This item should be handled manually
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      )
                  )}
                </ul>
              ) : (
                <p>
                  There are no prescriptions or consultations to bill for this
                  patient.
                </p>
              )}
            </div>
          </div>
          {hasPermission("/patient-billing", "Add") && (
            <div className="invoice-actions">
              <button onClick={handleProcessInvoice} disabled={processing}>
                {processing
                  ? "Processing..."
                  : bill
                  ? "Update Sales Record"
                  : "Process Invoice"}
              </button>
            </div>
          )}
          {salesNumber && (
            <div className="invoice-result">
              <p>
                Invoice processed successfully. Sales Number:{" "}
                <strong>{salesNumber}</strong>
              </p>
            </div>
          )}
        </>
      ) : (
        <p>No billing data available.</p>
      )}
    </div>
  );
};

export default PatientBillingForm;
