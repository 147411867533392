import React, { useState, useEffect } from 'react';
import DataList from '../DataList';
import { getFormattedISODateTime } from '../../assets/Utils';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ApiConfigFactory from '../../configurations/ApiConfigFactory';
import { formatDistanceToNow } from 'date-fns';

const PatientVisitList = () => {
    const [patientName, setPatientName] = useState('');
  //  const [patientId, setPatientID] = useState();
    const location = useLocation();
    const { patient } = location.state || {}; 




      const dlConfig = new ApiConfigFactory(
        'patients/visits', // serverRoute
        'All Visits',  // title
        'patient_id',       // parentIDName
        patient?.patient_id                // parentIDValue
      );

      dlConfig.title = patient?.patient_id? `Visit for Patient: ${patient?.patient_first_name} ${patient?.patient_last_name}`: 'All Visits';
      dlConfig.formTitle = patient?.patient_id? `Visit for Patient: ${patient?.patient_first_name} ${patient?.patient_last_name}`: '';
      dlConfig.allowAdd = patient?.patient_id?true:false;
      dlConfig.path = '/patient-visits';
      dlConfig.useLocalCaching = (patient && Object.keys(patient).length > 0)?false:true;
      
      dlConfig.addDropdownField({name: 'assigned_doctor',
                endpoint: '/hospital-portal/api/employees/doctors',
                valueField: 'doctor_name',
                labelField: 'doctor_name',
      });
      dlConfig.defaultSortFieldId = "visit_id";
      dlConfig.addStaticDropdownField( {
            name: 'branch',
            options:[
                { id: 'Pultney', name: 'Pultney'},
                { id: 'Kissy', name: 'Kissy'},

            ],
      });

      dlConfig
        .addField( { name: 'visit_id', label: 'Visit ID', type: 'number', identifier: true, disabled: true, tab: 'Visit Details' })
        .addField( { name: 'patient_id', label: 'Patient ID', type: 'number', tab: 'Visit Details',defaultValue:patient?.patient_id,visible:false })
        .addField( { name: 'visit_date', label: 'Visit Date', type: 'datetime-local', required: true, tab: 'Visit Details', defaultValue: getFormattedISODateTime() })
        .addField( { name: 'assigned_doctor', label: 'Assigned Doctor', type: 'dropdown', required: true, tab: 'Visit Details',defaultValue: 'Not Assigned' })
        .addField( { name: 'branch', label: 'Branch', type: 'dropdown', required: true, tab: 'Visit Details' })
        .addField( { name: 'symptoms', label: 'Symptoms', type: 'textarea', tab: 'Treatment', maxRows: 5 })
        .addField( { name: 'temperature', label: 'Temperature', type: 'number', required: false, tab: 'Vitals' })
        .addField( { name: 'blood_pressure_systolic', label: 'BP-Systolic', type: 'text', required: false, tab: 'Vitals' })
        .addField( { name: 'blood_pressure_diastolic', label: 'BP-Diastolic', type: 'text', required: false, tab: 'Vitals' })
        .addField( { name: 'pulse', label: 'Pulse', type: 'number', required: false, tab: 'Vitals' })
        .addField( { name: 'weight', label: 'Weight', type: 'number', required: false, tab: 'Vitals' })
        .addField( { name: 'spo2', label: 'SPO2', type: 'number', required: false, tab: 'Vitals' })
        .addField( { name: 'diagnosis', label: 'Diagnosis', type: 'textarea', required: false, tab: 'Treatment', maxRows: 5 })
        .addField( { name: 'proposed_treatment', label: 'Treatment Plan', type: 'textarea', required: false, tab: 'Treatment', maxRows: 5 })
        .addField( { name: 'invoiced', label: 'Invoice?', type: 'checkbox', required: false, tab: 'Admin'})
        .addField({ name: 'updatedBy', label: 'Updated By', type: 'text', required: false, tab: 'Admin' })
        .addField({ name: 'updatedAt', label: 'Updated At', type: 'datetime', required: false, tab: 'Admin', disabled: true })
        .addField({ name: 'createdAt', label: 'Created At', type: 'datetime', required: false, tab: 'Admin', disabled: true })
          /*.addField( { name: 'visitAttachment1', label: 'Visit Attachment 1', type: 'file', required: false, tab: 'Files',accept:'application/pdf' })
        .addField( { name: 'visitAttachment2', label: 'Visit Attachment 2', type: 'file', required: false, tab: 'Files',accept:'application/pdf' })*/
        .addField({
          name: 'prescription_items',
          label: 'Medicine',
          type: 'advanced-array',
          endpoint: '/hospital-portal/api/items/search/by-name',
          tab: 'Prescriptions',
          additionalFields: [
            { name: 'quantity', label: 'Quantity', type: 'number', width: '5%' },
            { name: 'instructions', label: 'Dosage', type: 'text', width: '35%' },
            { name: 'duration', label: 'Duration', type: 'text', width: '15%'}
          ]
        })
      
       dlConfig
        .addTab({ name: 'Visit Details', roles: 'all', default: true })
        .addTab( { name: 'Vitals', roles: 'Admin,Receptionist,Accountant,Cashier,Nurse,Doctor,SH Staff,Manager,Night Cashier and Receptionist' })
        .addTab({ name: 'Treatment', roles: 'Admin,Accountant,Cashier,Nurse,Doctor,Manager' })
       // .addTab({ name: 'Files', roles: 'Admin,Doctor,Nurse, Accountant,Manager' })
        .addTab({ name: 'Prescriptions', roles: 'Admin,Accountant,Doctor' })
        .addTab({ name: 'Admin', roles: 'Admin' });

       dlConfig
        .addColumn({ id: 'visit_id', name: 'Visit ID', selector: (row) => row.visit_id, type: 'number', sortable: true, identifier: true, width: '100px', filterable: true, filterSelector: 'visit_id' })
        .addColumn({
          name: 'Patient',
          type: 'link',
          label: (row) => `${row.Patient?.patient_first_name} ${row.Patient?.patient_last_name}`,
          path: '/patient-card',
          // The getState function will pass the entire row (i.e. patient object) in the navigation state.
          getState: (row) => ({ patient: row.Patient }),
          selector: (row) => `${row.Patient?.patient_first_name} ${row.Patient?.patient_last_name}`})
        .addColumn({ name: 'Patient #', selector: (row) => row.Patient.patient_number, type: 'text', sortable: true,filterable: true, filterSelector: 'Patient.patient_number' })
        .addColumn({ name: 'Type', selector: (row) => row.Patient.patient_type, type: 'text', sortable: true })
        .addColumn({ name: 'Visit Date', selector: (row) => row.visit_date, type: 'datetime', sortable: true })
        .addColumn({ name: 'Assigned Doctor', selector: (row) => row.assigned_doctor, sortable: true, filterable: true, filterSelector: 'assigned_doctor' })
        .addColumn({ name: 'Branch', selector: (row) => row.branch, sortable: true, filterable: true, filterSelector: 'branch' });
  return <DataList apiConfig={dlConfig} columns={dlConfig.columns} />;
};

export default PatientVisitList;
