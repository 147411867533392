import React from 'react';
import DataList from '../DataList';
import { getFormattedDate } from '../../assets/Utils';
import ApiConfigFactory from '../../configurations/ApiConfigFactory';

const handleAgeChange = (value, formData, setFormData) => {
  if (value && !formData.date_of_birth) {
    const today = new Date();
    const birthYear = today.getFullYear() - value;
    const calculatedDateOfBirth = new Date(birthYear, today.getMonth(), today.getDate());
    setFormData({
      ...formData,
      patient_dob: calculatedDateOfBirth.toISOString().split('T')[0], // Format as YYYY-MM-DD
    });
  }
};
const handleNameChange = (value, formData, setFormData,name) => {
  if(value) {
    const newValue = value.charAt(0).toUpperCase() + value.slice(1);

  setFormData((prev) => ({ ...prev, [name]: newValue }));
  }
};
const calculateAge = (value, formData) => {
  const dob = new Date(formData.patient_dob);
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--; // Adjust if the birthday hasn't occurred this year
  }
  return age; // Return the calculated age
};

const PatientList = () => {
  // Initialize apiConfig using ApiConfigFactory
  const apiConfig = new ApiConfigFactory('patients', 'Patients');
  apiConfig.defaultSortAsc = false;
  apiConfig.defaultSortFieldId = 'patient_id';
  apiConfig.path = '/patients';
  apiConfig.useLocalCaching = true;



  apiConfig.additionalValidation = (formData) => {
    let error = {};
    console.log("starting validation. company: ", formData.company, "patient_type: ", formData.patient_type);

    if(formData.patient_type === 'Company') {
      if(!formData.company) {
        error.message = 'Company is required for company patients.';
      }
    }

    if(formData.patient_type === 'Private') {
      if(formData.company) {
        error.message = 'Company should not be selected for private patients.';
      }
    }   

    if(error.message) {
      error.isValid = false;
    } else {
      error.isValid = true;
    }
    return error;

  };

  // Configure fields, columns, and other properties
  apiConfig
    .addField({ name: 'patient_id', label: 'Patient ID', type: 'text', identifier: true, visible: false, tab: 'Main' })
    .addField({ name: 'patient_number', label: 'Patient Number', type: 'text', disabled: true, tab: 'Main' })
    .addField({ name: 'patient_first_name', label: 'First Name', type: 'text', required: true, tab: 'Main',onChange: handleNameChange })
    .addField({ name: 'patient_middle_name', label: 'Middle Name', type: 'text', tab: 'Main',required: false})
    .addField({ name: 'patient_last_name', label: 'Last Name', type: 'text', required: true, tab: 'Main' })
    .addField({ name: 'patient_age2', label: 'Age', type: 'number', tab: 'Main', onChange: handleAgeChange, onInitialize: calculateAge })
    .addField({ name: 'patient_dob', label: 'Date of Birth', type: 'date', required: true, tab: 'Main' })
    .addField({ name: 'patient_sex', label: 'Gender', type: 'dropdown', required: true, tab: 'Main' })
    .addField({ name: 'mobile_number', label: 'Phone Number', type: 'text', required: true, tab: 'Main' })
    .addField({ name: 'email', label: 'Email', type: 'email', tab: 'Main',required: false })
    .addField({ name: 'patient_type', label: 'Type', type: 'dropdown', required: true, tab: 'Main' })
    .addField({ name: 'company', label: 'Company', type: 'dropdown', required: false, tab: 'Company/Insurance'})
    .addField({ name: 'company_careof_id', label: 'Company C/O ID', type: 'text', required: false, tab: 'Company/Insurance' })
    .addField({ name: 'company_careof_name', label: 'Company C/O Name', type: 'text', required: false, tab: 'Company/Insurance' })
    .addField({ name: 'insurance', label: 'Insurance', type: 'text', required: false, tab: 'Company/Insurance'})
    .addField({ name: 'registration_date', label: 'Registration Date', type: 'date', disabled: true, defaultValue: getFormattedDate(), tab: 'Other' })
    .addField({ name: 'address', label: 'Address', type: 'textarea', maxRows: 5, required: false, tab: 'Address' })
    .addField({ name: 'company_legacy', label: 'Old Company', type: 'text', tab: 'Admin' })
    .addField({ name: 'legacy_files', label: 'Old Patient Records', type: 'file', tab: 'Other', accept: 'application/pdf' })

    .addStaticDropdownField({
      name: 'patient_sex',
      options: [{ id: 'Male', name: 'Male' }, { id: 'Female', name: 'Female' }],
    })
    .addStaticDropdownField({
      name: 'patient_type',
      options: [{ id: 'Private', name: 'Private' }, { id: 'Company', name: 'Company' },{ id: 'Staff', name: 'Staff' }],
    })
    .addDropdownField({
      name: 'company',
      endpoint: '/hospital-portal/api/customers?status=Active',
      valueField: 'customer_name',
      labelField: 'customer_name',
    })
    .addTab({ name: 'Main', roles: 'all', default: true })
    .addTab({ name: 'Company/Insurance', roles: 'all' })
    .addTab({ name: 'Address', roles: 'all' })
    .addTab({ name: 'Other', roles: 'all' })

    .addBulkAction({ name: 'Generate Report', endpoint: '/hospital-portal/api/medical-contracts/generate-report' })


    // Optionally remove or leave any other link fields if not needed.
    // Replace the three separate link columns with one Detail column.
    .addColumn({
      name: 'Detail',
      type: 'link',
      label: 'Detail',
      path: '/patient-card',
      // The getState function will pass the entire row (i.e. patient object) in the navigation state.
      getState: (row) => ({ patient: row }),
    })
    .addColumn({ id: 'patient_id', name: 'Patient ID', selector: (row) => row.patient_id, type: 'text', sortable: true, identifier: true, width: '150px', omit: true })
    .addColumn({ name: 'Patient Number', selector: (row) => row.patient_number, sortable: true, filterable: true, filterSelector: 'patient_number' })
    .addColumn({ name: 'First Name', selector: (row) => row.patient_first_name, sortable: true, filterable: true, filterSelector: 'patient_first_name' })
    .addColumn({ name: 'Last Name', selector: (row) => row.patient_last_name, sortable: true, filterable: true, filterSelector: 'patient_last_name' })
    .addColumn({ name: 'Date of Birth', selector: (row) => row.patient_dob, type: 'date', sortable: true })
    .addColumn({ name: 'Gender', selector: (row) => row.patient_sex, sortable: true })
    .addColumn({ name: 'Phone Number', selector: (row) => row.mobile_number, sortable: true, filterable: true, filterSelector: 'mobile_number' })
    .addColumn({ name: 'Registration Date', selector: (row) => row.registration_date, type: 'date', sortable: true });

  return <DataList apiConfig={apiConfig} columns={apiConfig.columns} />;
};

export default PatientList;
