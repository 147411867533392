import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus, faTasks, faCheck, faTimes, faFileExcel,faEye,faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import FormModal from './FormModal';
import ViewModal from './ViewModal';
import '../styles/DataList.css';
import { useAuth } from '../hooks/useAuth';
import { CSVLink } from "react-csv";
import { getFormattedDateTime } from '../assets/Utils';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { openDB } from 'idb';
import * as XLSX from 'xlsx'; 

const DataList = ({ apiConfig, columns }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { rolePermissions, user, token } = useAuth();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const navigate = useNavigate();  

  useEffect(() => {
    fetchData();
  }, [isOnline]);

  // Monitor online/offline status
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);


  useEffect(() => {
    applyFilters();
  }, [filters, data]);

  const fetchData = async () => {
    setIsLoading(true);
  
    try {
      const useLocalCaching = apiConfig.useLocalCaching;
      const entityName = apiConfig.title.replace(/\s+/g, ''); // Strip whitespace from the title
  
      if (useLocalCaching) {

        let dbVersion = parseInt(localStorage.getItem('dbVersion'), 10);
        if (!dbVersion) {
          dbVersion = 2;
          localStorage.setItem('dbVersion', dbVersion);
        }

        const keyColumn = columns.find((col) => col.identifier === true);
        const keyPath = keyColumn ? keyColumn.id : 'id'; // Fallback to 'id' if none is marked

        console.log("appDB version number: ",dbVersion);

        const db = await openDB('appDB', dbVersion, {
          upgrade(db) {
            console.log("loading db with upgrade");
            if (!db.objectStoreNames.contains(entityName)) {
              db.createObjectStore(entityName, { keyPath }); // Ensure object store exists
            }
          },
        });

        if (!db.objectStoreNames.contains(entityName)) {
          console.log("object number found incrementing dbVersion");
          dbVersion++;
          localStorage.setItem('dbVersion', dbVersion);
          db = await openDB('appDB', dbVersion, {
            upgrade(db) {
              if (!db.objectStoreNames.contains(entityName)) {
                console.log("Creating object ",entityName," with version:",dbVersion);
                db.createObjectStore(entityName, { keyPath });
              }
            },
          });
        }
  
        if(isOnline) { 
            // Retrieve the last sync timestamp
            const lastSync = localStorage.getItem(`lastSync_${entityName}`) || '1970-01-01T00:00:00Z';
            console.log("Geting data with last sync date of: ", lastSync);
            // Fetch incremental updates from the server
            const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.fetchEndpoint}`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { lastSync: lastSync }, // Use updatedAt for incremental updates
            });
    
            const updatedRecords = response.data;
    
            // Save updated records to IndexedDB
            const tx = db.transaction(entityName, 'readwrite');
            const store = tx.objectStore(entityName);
    
            for (const record of updatedRecords) {
            store.put(record); // Insert or update records
            }
            await tx.done;
    
            // Update lastSync timestamp
            localStorage.setItem(`lastSync_${entityName}`, new Date().toISOString());
  
        }
        // Retrieve all data from IndexedDB and update React state
        const allData = await db.getAll(entityName);
        setData(allData);
      } else {
        // Fallback: Fetch all data from the server
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.fetchEndpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getValueBySelector = (item, selector) => {
    if (!selector) return null;
    const keys = selector.split('.');
    return keys.reduce((value, key) => (value ? value[key] : ''), item);
  };

  const applyFilters = () => {
    const processedFilters = {};
    Object.keys(filters).forEach((key) => {
      const column = columns.find((col) => col.name === key);
      const isInteger = column?.type === 'integer';
      processedFilters[key] = isInteger ? parseInt(filters[key], 10) : filters[key].toLowerCase();
    });

    if (apiConfig.systemFilters) {
        apiConfig.systemFilters.forEach((filter) => {
          const column = columns.find((col) => col.name === filter.name);
          const isInteger = column?.type === 'integer';
          processedFilters[filter.name] = isInteger ? parseInt(filter.value, 10) : filter.value.toLowerCase();
        });
      }
    
    const filteredData = data.filter((item) =>
      Object.keys(processedFilters).every((key) => {
        const filterValue = processedFilters[key];
        const column = columns.find((col) => col.name === key);
        const selector = column?.filterSelector || key;
        const itemValue = getValueBySelector(item, selector);

        if (column?.type === 'integer') {
          return !filterValue || itemValue === filterValue;
        }

        return !filterValue || String(itemValue || '').toLowerCase().includes(filterValue);
      })
    );

    setFilteredData(filteredData);
  };


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const clearFilters = () => {
    setFilters({});
  };

  const renderFilters = () => {
    return (
      <div className="filter-container">
        {columns
          .filter((column) => column.filterable)
          .map((column) => (
            <div key={column.name} className="filter-item">
              <input
                type="text"
                name={column.name}
                placeholder={`Filter by ${column.name}`}
                value={filters[column.name] || ''}
                onChange={handleFilterChange}
              />
            </div>
          ))}
        <button className="clear-filters" onClick={clearFilters}>
          Clear Filters
        </button>

        <button className="export-button" onClick={handleExcelExport}>Export <FontAwesomeIcon icon={faFileExcel} /></button>
      </div>
    );
  };
  const handleExcelExport = async () => {
    try {
      // Use columns to map and extract values from filteredData
      const processedData = filteredData.map((row) => {
        const processedRow = {};
  
        columns.forEach((col) => {
          // Use the selector function if it exists, otherwise use the property name
          if (col.selector) {
            processedRow[col.name] = col.selector(row);
          } else if (col.id) {
            processedRow[col.name] = row[col.id];
          } else {
            processedRow[col.name] = row[col.name];
          }
        });
  
        return processedRow;
      });
  
      // Convert the processed data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(processedData);
  
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
  
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
  
      // Export the workbook as an Excel file
      XLSX.writeFile(workbook, `${apiConfig.title.replace(/\s+/g, '_').toLowerCase()}_export.xlsx`);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };
  
  const handleDelete = async (row) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      try {
        const id = getIdentifierValue(row);

        await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}${apiConfig.deleteEndpoint}/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            }
        );

      // If local caching is enabled, remove the record from local IndexedDB.
      if (apiConfig.useLocalCaching) {
        const entityName = apiConfig.title.replace(/\s+/g, '');
        const dbVersion = parseInt(localStorage.getItem('dbVersion'), 10) || 2;
        const db = await openDB('appDB', dbVersion, {
          upgrade(db) {
            if (!db.objectStoreNames.contains(entityName)) {
              db.createObjectStore(entityName, { keyPath: 'id' });
            }
          },
        });
        await db.delete(entityName, id);
      }

        fetchData();
      } catch (error) {
        console.error('Error deleting record:', error);
        alert('Failed to delete record.');
      }
    }
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    if(apiConfig.onEdit){
      apiConfig.onEdit(record);
    } else {  
      setIsViewModalVisible(false);
      setIsModalVisible(true);
    }
  };

  const handleAdd = () => {
    if(isButtonsDisabled) return;
    setSelectedRecord(null);
    setIsViewModalVisible(false);
    setIsModalVisible(true);
    setIsButtonsDisabled(true);
  }

  const handleView = (record) => {
    setSelectedRecord(record);
    setIsViewModalVisible(true);
  };
  

  const handleBulkAction = async (action) => {
    if (selectedRows.length === 0) {
      alert('Please select at least one record for bulk action.');
      return;
    }

    if (window.confirm(`Are you sure you want to execute '${action.name}' for the selected records?`)) {
      try {
        const selectedIds = selectedRows;
        const payload = {
          ids: selectedIds,
          updatedBy: user.portal_id,
        };

        await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}${action.endpoint}`,
          payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
  
            }
        );
        alert(`Bulk action '${action.name}' executed successfully!`);
      } catch (error) {
        console.error(`Error executing bulk action '${action.name}':`, error);
        alert(`Failed to execute '${action.name}': ${error.response?.data?.error}`);
      } finally {
        fetchData();
        setSelectedRows([]);
      }
    }
  };

  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows.map((row) => getIdentifierValue(row)));
  };

  const renderColumnCell = (column, row) => {
    if (column.visible && typeof column.visible === 'function' && !column.visible(row)) {
      return null;
    }
    // Handle boolean type with icons
    if (apiConfig.conditionalStyles?.[column.name]) {
        const conditions = apiConfig.conditionalStyles[column.name];
        const style = calculateConditionalStyle(column.selector(row), conditions);
        return <span style={{...style,"padding":"5px"}}>{column.selector(row)}</span>;
      }
    
      if (column.type === 'boolean') {
        return column.selector(row) ? (
          <FontAwesomeIcon icon={faCheck} className="boolean-true" />
        ) : (
          <FontAwesomeIcon icon={faTimes} className="boolean-false" />
        );
      }
      if(column.type === 'datetime') {
        return column.selector(row) ? (getFormattedDateTime(column.selector(row))):('');
      }
    
      if(column.type === 'link') {
        const label =
        typeof column.label === 'function' ? column.label(row) : column.label;
      return (
        <Link
          to={column.path}
          state={typeof column.getState === 'function' ? column.getState(row) : column.getState}
        >
          {label}
        </Link>
      );
      }
      return column.selector(row);
  };
  
  const getIdentifierValue = (row) => {
    const identifierCol = columns.find((col) => col.identifier);
    return identifierCol ? identifierCol.selector(row) : null;
  };  
  
  const calculateConditionalStyle = (value, style) => {
    if (style.conditions.min !== undefined || style.conditions.max !== undefined) {
      const { min, max, colors } = style.conditions;
      if ((min !== undefined && value < min) || (max !== undefined && value > max)) {
        return colors.outOfRange ;
      }
      return colors.withinRange;
    }
  
    if (style.type === 'text' && style.conditions.matches) {
      const color = style.conditions.matches[value] || style.conditions.default;
      return color;
    }
  
    return {};
  };

  return (
    rolePermissions?.[apiConfig.path]?.View !== 'true' ? (
        <div className="noAccess">
            <FontAwesomeIcon icon={faExclamationTriangle} className="noAccess-icon" />
            <p>You do not have permission to access this page.</p>
        </div>
    ) : (
    
    <div className="data-list">


      <div className="header">
        <h2>{apiConfig.title}</h2>
        {!isOnline && <p className="offline-warning">You are OFFLINE. Limitted functionality until intternet is restored.</p>}
        <div className="actions">
          {rolePermissions?.[apiConfig.path]?.Add === 'true' && apiConfig.allowAdd && (
            <button disabled={!isOnline || isButtonsDisabled} className="add-button" onClick={() => handleAdd()}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          )}
          {selectedRows.length > 0 && rolePermissions?.[apiConfig.path]?.Edit === 'true' && (
            <div className="bulk-actions">
              <FontAwesomeIcon icon={faTasks} />
              <select
                disabled={!isOnline}
                defaultValue=""
                onChange={(e) =>
                  handleBulkAction(apiConfig.bulkActions.find((action) => action.name === e.target.value))
                }
              >
                <option value="">Select Action</option>
                {apiConfig.bulkActions && apiConfig.bulkActions.length > 0
                  ? apiConfig.bulkActions.map((action) => (
                      <option key={action.name} value={action.name}>
                        {action.name}
                      </option>
                    ))
                  : <option value="" disabled>No actions available</option>}
              </select>
            </div>
          )}
        </div>
      </div>

      <DataTable
        columns={[
          ...columns.map((col) => ({
            ...col,
            cell: (row) => renderColumnCell(col, row),
          })),
          {
            name: 'Actions',
            cell: (row) => (
              <>
                <button onClick={() => handleView(row)}>
                    <FontAwesomeIcon icon={faEye} /> View
                </button>
                {rolePermissions?.[apiConfig.path]?.Edit === 'true' && (
                  <button disabled={!isOnline} onClick={() => handleEdit(row)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                )}
                {rolePermissions?.[apiConfig.path]?.Delete === 'true' && (
                  <button disabled={!isOnline} onClick={() => handleDelete(row)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </>
            ),
          },
        ]}
        data={filteredData}
        progressPending={isLoading}
        selectableRows
        onSelectedRowsChange={handleSelectedRowsChange}
        pagination
        paginationPerPage={50}
        highlightOnHover
        defaultSortFieldId={apiConfig.defaultSortFieldId} // New default sort field
        defaultSortAsc={apiConfig.defaultSortAsc ?? true} // New sort order
        subHeader
        subHeaderComponent={renderFilters()}
       // onRowClicked={handleView} 
      />

      {isModalVisible && (
        <FormModal
          initialData={selectedRecord}
          onClose={() => {
            setIsModalVisible(false);
            setIsButtonsDisabled(false);
            setSelectedRecord(null);
            fetchData();
          }}
          apiConfig={apiConfig}
        />
      )}
      {isViewModalVisible && (
    <ViewModal
        record={selectedRecord}
        onClose={() => setIsViewModalVisible(false)}
        onEdit={(record) => {
        setIsViewModalVisible(false);
        handleEdit(record);
        }}
        apiConfig={apiConfig}
        editable={rolePermissions?.[apiConfig.path]?.Edit === 'true'}
    />
    )}

    </div>
)
  );
};

export default DataList;
