import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/LabTestRequestForm.css';
import { useAuth } from '../../hooks/useAuth';

const LabTestRequestForm = () => {
  const { user, token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  // Extract patient object from navigation state.
  const { patient: patientFromState } = location.state || {};
  
  // Local state
  const [patient, setPatient] = useState(patientFromState || null);
  const [labTests, setLabTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [branch, setBranch] = useState('');
  
  // New state: active tab (Laboratory or Radiology)
  const [activeTab, setActiveTab] = useState("Laboratory");

  // Fetch lab tests
  const fetchLabTests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/labTests`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLabTests(response.data);
    } catch (error) {
      console.error('Error fetching lab tests:', error);
      toast.error('Error fetching lab tests.');
    }
  };

  useEffect(() => {
    if (patientFromState) {
      fetchLabTests()
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [token, patientFromState]);

  // Handle checkbox changes
  const handleCheckboxChange = (testId, testName, checked) => {
    if (checked) {
      setSelectedTests((prev) => [...prev, { Lab_Test: testId, Test_Description: '' }]);
    } else {
      setSelectedTests((prev) => prev.filter((test) => test.Lab_Test !== testId));
    }
  };

  // Submit selected tests and branch
  const submitRequest = async () => {
    if (!branch) {
      toast.error('Please select a Branch before submitting.');
      return;
    }
    if (selectedTests.length === 0) {
      toast.warn('Please select at least one test before submitting.');
      return;
    }

    setIsSubmitting(true);
    const requestData = {
      patient_id: patient ? patient.patient_id : null,
      tests: selectedTests,
      updatedBy: user.portal_id,
      requested_by: user.name,
      requested_branch: branch,
      request_type: activeTab
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/hospital-portal/api/lab/lab-requests`,
        requestData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success('Request submitted successfully.');
      setTimeout(() => navigate(-1), 1500);
    } catch (error) {
      console.error('Error submitting request:', error);
      toast.error('Error submitting request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Cancel the request with a confirmation if there are unsaved changes.
  const cancelRequest = () => {
    if (selectedTests.length > 0) {
      const confirmCancel = window.confirm(
        'You have unsaved changes. Are you sure you want to cancel?'
      );
      if (!confirmCancel) return;
    }
    navigate(-1);
  };

  // Group tests by category and type AFTER filtering by activeTab.
  const groupedTests = useMemo(() => {
    // Filter tests based on test_type matching activeTab.
    const filteredTests = labTests.filter(test => {
      // If test_type is missing, default it to "Laboratory"
      const labType = test.lab_type || "Laboratory";
      return labType === activeTab;
    });

    return filteredTests.reduce((acc, test) => {
      if (!acc[test.category]) {
        acc[test.category] = {};
      }
      if (!acc[test.category][test.type]) {
        acc[test.category][test.type] = {};
      }

      // Extract attribute from test name
      const attribute = test.test_name.includes('-AP') ? 'AP' : test.test_name.includes('-LAT') ? 'LAT' : 'None';
      const baseTestName = test.test_name.replace('-AP', '').replace('-LAT', '').trim();

      if (!acc[test.category][test.type][baseTestName]) {
        acc[test.category][test.type][baseTestName] = { test_name: baseTestName, attributes: [] };
      }

      if (attribute !== 'None') {
        acc[test.category][test.type][baseTestName].attributes.push({ id: test.id, attribute });
      } else {
        acc[test.category][test.type][baseTestName].attributes.push({ id: test.id, attribute: 'None' });
      }

      return acc;
    }, {});
  }, [labTests, activeTab]);

  useEffect(() => {
    setSelectedTests([]);
  }, [activeTab]);

  if (isLoading) {
    return <div>Loading patient details and lab tests...</div>;
  }

  return (
    <div className="medical-lab-widget">
      <ToastContainer />
      {/* Header Section */}
      <div className="header">
        <div className="patient-info">
          {patient ? (
            <>
              <div className="patient-info-row">
                <strong>Patient #:</strong> <span>{patient.patient_number}</span>
              </div>
              <div className="patient-info-row">
                <strong>Name:</strong> <span>{patient.patient_first_name} {patient.patient_last_name}</span>
              </div>
              <div className="patient-info-row">
                <strong>Date of Birth:</strong> <span>{patient.patient_dob}</span>
              </div>
            </>
          ) : (
            <span>Patient details not found.</span>
          )}
        </div>

        <div className="header-buttons">
          <button onClick={submitRequest} disabled={isSubmitting || selectedTests.length === 0}>
            {isSubmitting ? 'Submitting...' : 'Submit Request'}
          </button>
          <button onClick={cancelRequest} disabled={isSubmitting}>
            Cancel
          </button>
        </div>
      </div>

      {/* Branch Section */}
      <div className="branch-input-container">
        <label htmlFor="branchSelect">Branch:</label>
        <select
          id="branchSelect"
          value={branch}
          onChange={(e) => setBranch(e.target.value)}
        >
          <option value="">Select Branch</option>
          <option value="Pultney">Pultney</option>
          <option value="Kissy">Kissy</option>
        </select>
      </div>

      {/* Tab Navigation */}
      <div className="tab-navigation">
        <button 
          className={activeTab === "Laboratory" ? "active-tab" : ""}
          onClick={() => setActiveTab("Laboratory")}
        >
          Laboratory
        </button>
        <button 
          className={activeTab === "Radiology" ? "active-tab" : ""}
          onClick={() => setActiveTab("Radiology")}
        >
          Radiology
        </button>
      </div>

      {/* Main Form Section */}
      <div id="mainForm">
        {Object.entries(groupedTests).map(([category, types]) => (
          <div key={category} className="category">
            <h3>{category}</h3>
            <div className="types-container">
              {Object.entries(types).map(([type, tests]) => (
                <div key={type} className="type">
                  <h4>{type}</h4>
                  <div className="tests">
                    {Object.entries(tests).map(([baseTestName, { test_name, attributes }]) => (
                      <div key={baseTestName} className="test-group">
                        {attributes.length > 1 ? (
                          <div className="attribute-group">
                            {attributes.map(({ id, attribute }) => (
                              <label key={id} className="attr-checkbox-label">
                                {attribute} 
                                <input
                                  type="checkbox"
                                  className="test-checkbox"
                                  data-test-id={id}
                                  data-test-name={`${test_name} - ${attribute}`}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      id,
                                      `${test_name} - ${attribute}`,
                                      e.target.checked
                                    )
                                  }
                                />
                              </label>
                            ))}
                            <span className="attr-test-name">{test_name}</span>
                          </div>
                        ) : (
                          attributes.map(({ id }) => (
                            <label key={id} className="test-checkbox-label">
                              <input
                                type="checkbox"
                                className="test-checkbox"
                                data-test-id={id}
                                data-test-name={test_name}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    id,
                                    test_name,
                                    e.target.checked
                                  )
                                }
                              />
                              {test_name}
                            </label>
                          ))
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LabTestRequestForm;
